@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/droidserif/v19/tDbI2oqRg1oM3QBjjcaDkNr6.ttf) format('truetype');
}
@font-face {
  font-family: 'Droid Serif';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/droidserif/v19/tDbV2oqRg1oM3QBjjcaDkOJGiSD8.ttf) format('truetype');
}
