/* template common css */
.icon-sort {
  display: none;
}

.row-container-outer {
  position: relative;
  background-color: transparent;
  background-image: none;
  background-position: left top;
  background-repeat: no-repeat;
  padding-left: 15px;
  padding-right: 15px;
}

.row-content {
  position: relative;
  margin: auto;
  max-width: 1140px;
  width: 100%;
}

.widget {
  position: relative;
  z-index: 2;
}

.widget-content {
  height: 100%;
}

.column-Outer {
  /* padding: 5px 0px; */
  min-height: 40px;
  position: relative;
  z-index: 1;
}

.overlay:after {
  content: "";
  position: absolute;
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
  width: 100%;
  height: 100%;
  background-color: rgba(0, 0, 0, 0.1);
  z-index: -1;
}

/* Navbar css */
.main-navbar {
  /* padding-left: 1.5rem;
  padding-right: 1.5rem; */
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.navbar .nav-link {
  padding-right: 0.5rem;
  padding-left: 0.5rem;
  font-size: 1.125rem;
  line-height: 1.75rem;
  font-weight: 500;
  /* font-family: var(--Poppins-family); */
  color: rgb(55, 65, 81);
}

.navbar .nav-link.active, .navbar .nav-link:hover {
  color: rgb(8, 108, 208);
}

/* buttons css */
.btn {
  padding: 0.5rem 1rem;
  font-weight: 600;
  font-size: 1rem;
  border: 1px solid var(--gray-500);
  background-color: transparent;
  color: #000;
  cursor: pointer;
}

/* .btn-book {
    padding: 0.6rem 1.5rem;
    background-color: rgb(29, 78, 216);
    color: #fff;
    border-color: transparent;
} */
/* header css */
/* .Banner {
    height: 500px;
} */
.banner-title {
  font-size: 2rem;
}

/* service layout css */
.apo-title {
  font-size: 1.5rem;
  font-weight: 500;
}

.service-wrapper {
  font-size: 0.9rem;
  line-height: 1.7;
}

.copyright {
  padding: 1rem 0;
  font-size: 1rem;
}

.copyright a {
  cursor: pointer;
  color: inherit;
  font-size: inherit;
  font-weight: inherit;
}