.textED-content h1,
.textED-content h2,
.textED-content h3,
.textED-content h4 {
  margin-bottom: 8px !important;
}

.font__W500 {
  font-weight: 500;
}

.reviewfeild {
  padding: 0.95rem 0.75rem;
  font-size: 1rem;
  line-height: normal;
}

.gblfont {
  font-family: var(--Poppins-family);
}

.reviewstar i {
  cursor: pointer;
}

.reviewList {
  padding-top: 1.5rem;
}

/* .appointmentSlot .availableSlot, .appointmentSlot .breakHour {  
    color: #fff;
    padding: 11px 0;
    width: 100%;
    max-width: 108px;
    text-align: center;
    border-radius: 4px;
    cursor: pointer;
} */
.no-slots {
  padding: 30px 20px;
  background-color: #f8fafc;
  width: 100%;
  border-radius: 8px;
}

/* .appointmentSlot .breakHour {
    background: #cecece;
    cursor: not-allowed;
} */
.signup .custom-select,
.signup .custom-select__option--value {
  height: inherit;
  font-size: inherit;
  color: inherit;
  font-weight: inherit;
  border: none;
  border-bottom: inherit;
  border-radius: inherit;
  background-color: inherit;
  border-color: inherit;
}

.signup .custom-select--dropup .custom-select__dropdown {
  border-color: inherit;
  margin-bottom: -2px;
  z-index: 6;
}

.signup .custom-select__dropdown {
  border-color: inherit;
  margin-top: -2px;
}

.signup .custom-select--dropup.custom-select--active .custom-select__option--value {
  border-bottom-color: inherit;
  border-radius: inherit;
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.signup .custom-select--active .custom-select__option--value {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}