@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 300;
  src: url(https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCJW8Dbw.ttf) format('truetype');
}
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 400;
  src: url(https://fonts.gstatic.com/s/oxygen/v15/2sDfZG1Wl4Lcnbu6iQ.ttf) format('truetype');
}
@font-face {
  font-family: 'Oxygen';
  font-style: normal;
  font-weight: 700;
  src: url(https://fonts.gstatic.com/s/oxygen/v15/2sDcZG1Wl4LcnbuCNWgDbw.ttf) format('truetype');
}
