.ck-dropdown:not(.ck-toolbar-dropdown) .ck-dropdown__panel {
  max-height: 280px;
  overflow-y: auto;
}

.ck.ck-content.ck-editor__editable_inline > :first-child, .ck.ck-content.ck-editor__editable_inline > :last-child {
  margin-top: 0;
  margin-bottom: 0;
}

.ck.ck-editor__editable_inline {
  padding-left: 0px !important;
  padding-right: 0px !important;
}

.ck .ck-link_selected {
  background: transparent !important;
}

.ck a {
  cursor: pointer;
}