input[type=number] {
  -webkit-appearance: textfield;
  -moz-appearance: textfield;
  appearance: textfield;
}

input[type=number]::-webkit-inner-spin-button,
input[type=number]::-webkit-outer-spin-button {
  -webkit-appearance: none;
}

input:disabled {
  cursor: not-allowed;
}

.has-error, .no-data {
  color: #dc3545;
}

.buttonstyle:disabled, button:disabled {
  opacity: 0.5;
  cursor: not-allowed !important;
}

#app {
  width: 100%;
  height: 100%;
  min-height: 100%;
}

.EditorHeader {
  border-bottom: 1px solid var(--gray-100);
  background-color: #fff;
  padding: 1rem 1.5rem;
  /* box-shadow: 0 0 20px rgb(0, 0, 0, 0.20); */
  position: fixed;
  z-index: 1;
}

.btn-primary {
  padding: 8px;
  cursor: pointer;
  background-color: transparent;
  border-radius: 8px;
  border: 1px solid var(--gray-200);
}

.topbar-save {
  max-width: 110px;
  color: #fff;
  background-color: var(--primary-color);
}

.btn-upload {
  max-width: 110px;
  color: #fff;
  background-color: var(--primary-color);
}

.EditorMain-wrapper {
  position: relative;
  margin-top: 72px;
  height: calc(100% - 72px);
}

.EditorContent-wrapper {
  display: flex;
  flex-direction: row;
  flex-wrap: wrap;
  position: relative;
  height: 100%;
}

.Editor__component {
  flex: 1 1;
  width: 100%;
  border-right: 1px solid #c7c7c7;
  height: 100%;
  overflow-y: auto;
}

.sidebar__component {
  width: 411px;
  background: #f9f9f9;
  color: #8f9699;
}

/* Template preview css */
.template-preview {
  position: fixed;
  background: #777;
  left: 0;
  right: 0;
  top: 72px;
  bottom: 0;
  z-index: 133;
}

.template-preview__title-bar {
  padding: 10px 20px;
  background: #fff;
  border-bottom: 1px solid var(--gray-100);
}

.template-preview_title {
  font-weight: 500;
  font-size: 1rem;
}

.viewportButton {
  cursor: pointer;
  padding: 0px 6px;
  color: #8d9097;
  border: none;
  background: transparent;
}

.viewportButton:hover, .viewportButton.selected {
  color: #393d47;
  border-radius: 3px;
  -webkit-box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
  box-shadow: 0 1px 3px rgba(0, 0, 0, 0.1);
}

.viewportButton.selected {
  background-color: var(--primary-color);
  color: #fff;
}

.template-preview--right {
  width: 22px;
  height: 22px;
  color: #fff;
  border-radius: 50%;
  background-color: rgba(0, 0, 0, 0.6);
}

.template-preview__viewport {
  height: calc(100% - 46px);
  /* overflow-y: auto; */
  position: relative;
}

.viewport--content {
  height: 90%;
  padding: 3px;
  border: 1px #ccc;
  background-color: #fff;
  border-radius: 5px;
}

.viewport--content[data-viewport=desktop], .viewport--content[data-viewport=tablet], .viewport--content[data-viewport=phone] {
  position: relative;
  margin: 60px auto 0 auto;
  top: 0;
  left: auto;
  /* overflow-y: auto; */
  min-width: 150px;
  min-height: 150px;
}

.viewport--content[data-viewport=fullscreen] {
  border: 0;
  padding: 0;
}

/* Template preview css end*/
/* loader mask screen */
.APO__screenmask {
  position: absolute;
  visibility: hidden;
  opacity: 0;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: rgba(255, 255, 255, 0.8);
  z-index: 10;
}

.APO__screenmask.show {
  visibility: visible;
  opacity: 1;
}

.APO__screenmask.hide {
  display: none;
}

.APO__spin {
  border-radius: 50%;
  position: relative;
  aspect-ratio: 1;
  border: 1px solid var(--primary-color);
  border-right-color: var(--primary-light-color);
  animation: oval-loading 1s linear infinite;
}

.APO__screenmask .APO__spin {
  /* width: 50px; */
  border-width: 3px;
}

@keyframes oval-loading {
  to {
    transform: rotate(1turn);
  }
}
/* custom select */
.custom-select .custom-select__option--value:before {
  content: "";
  border-left: 1px solid var(--gray-100);
  height: 100%;
  top: 0;
  position: absolute;
  right: 29px;
}

.custom-select__option:hover, .custom-select__option:focus {
  background-color: transparent;
}

.rounded-e-none .custom-select__option--value {
  border-start-end-radius: 0px !important;
  border-end-end-radius: 0px !important;
}

.customS-arrowbox-0 .custom-select .custom-select__option--value:before {
  border-left: none;
}

/* UI Tab */
.ui-widget {
  font-family: inherit;
}

.custom-Tabs {
  /* width: 100%;
  height: 100%; */
  border: none !important;
  padding: 0px 0 5px;
  background: transparent;
}

.custom-Tabs .ui-tabs-nav {
  background: transparent;
  border-width: 0 0 1px;
  border-style: solid;
  border-color: rgba(26, 40, 61, 0.24);
  padding: 10.5px 15px 10.5px 22px;
}

.custom-Tabs .ui-tabs-nav li {
  margin: 0px;
  width: 100%;
  border: none;
  background-color: transparent;
  float: none;
}

.custom-Tabs .ui-tabs-nav li a {
  text-align: center;
  width: 100%;
  color: var(--primary-color);
  background-color: var(--primary-light-color);
  border-radius: 8px;
  font-weight: 500;
  font-family: var(--Poppins-family);
  float: none !important;
  padding: 0.7rem !important;
}

.custom-Tabs .ui-tabs-nav li:not(:last-child) a {
  margin-right: 0.5rem;
}

.custom-Tabs.ui-tabs .ui-tabs-nav li.ui-state-active a {
  color: #fff;
  background-color: var(--primary-color);
}

.custom-Tabs .ui-tabs-panel {
  padding: 0px;
  height: calc(100% - 58px);
}

.tabsContent-wrapper {
  padding: 0px;
  height: 100%;
  overflow-y: auto;
  background-color: #fff;
}

.property-wrapper {
  overflow-y: auto;
  background-color: #f9f9f9;
}

.property__component .property-wrapper {
  height: calc(100% - 130px);
}

.editRowprop .property-wrapper {
  height: calc(100% - 58px);
}

.plainTab {
  border: none !important;
  padding: 0;
}

.plainTab .ui-tabs-nav {
  padding: 7px 0px;
  margin-bottom: 1rem;
  background-color: #fff;
  border: 1px solid #e6e6e6;
  border-radius: 12px;
}

.plainTab .ui-tabs-nav li {
  margin: 0px;
  border: none;
  border-radius: 12px;
  background-color: transparent;
}

.plainTab .ui-tabs-nav li.ui-tabs-active {
  background: var(--primary-color);
}

.plainTab .ui-tabs-nav .ui-tabs-anchor {
  float: none;
  padding: 0.6rem;
}

.plainTab .plainTab-wrapper {
  padding: 0px;
}

/* number field */
.number-field {
  padding-right: 19px !important;
}

.number-plusminus {
  position: absolute;
  top: 0px;
  right: 0;
  width: 22px;
  height: 35px;
}

.number-plusminus button {
  background-color: transparent;
  border: none;
  padding: 0;
  height: 100%;
  cursor: pointer;
}

/* Custom select */
.custom-select__dropdown {
  overflow-y: auto;
  max-height: 11.25rem;
  border-top: 1px solid var(--gray-100);
}

.custom-select__dropdown::-webkit-scrollbar {
  display: none;
}

.custom-select--dropup .custom-select__dropdown {
  border-bottom: 1px solid var(--gray-100);
  /* border-top: none; */
}

/* UI slider */
.custom-uiSlider.ui-slider-horizontal {
  height: 0.5em;
  background-color: var(--primary-light-color);
  border: none !important;
}

.custom-uiSlider .ui-slider-range {
  background-color: var(--primary-color);
}

.custom-uiSlider .ui-slider-handle {
  border-radius: 50%;
  border-color: var(--gray-100);
  background-color: var(--primary-color);
}

.custom-uiSlider .ui-state-focus {
  border: none;
  outline: none;
}

.Rangeslider .custom-select__option--value:after {
  display: none;
}

.Rangeslider .custom-select__option--value {
  padding-right: 0.75rem;
  width: 43px;
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

/* UI accordion */
.accordion-setting .setting-title {
  background-color: #f9f9f9;
  border: none;
  color: #000;
  font-weight: 500;
  font-size: 1rem;
  padding: 0.9rem 1.3rem;
  margin-top: 0px;
}

.accordion-setting .setting-title:after {
  content: "";
  background-image: url(../images/icons/chevron-down-solid.svg);
  position: absolute;
  right: 19px;
  width: 16px;
  height: 16px;
}

.accordion-setting .ui-accordion-header-icon {
  display: none;
}

.custom-accordion .setting-panel {
  border: none;
  padding: 0.7rem 1.3rem;
  overflow: visible;
}

.ui-widget input, .ui-widget select, .ui-widget textarea, .ui-widget button:not([class*=custom-select]) {
  font-family: var(--Poppins-family);
}

/* Signup form  UI accordion*/
.signuPropAccordion .formAcrdnHandle {
  display: flex;
  margin: 0;
  padding: 0px;
  border: 1px solid var(--gray-100);
  border-radius: 0;
  background-color: #ffffff;
  color: #000;
  border-radius: 0.25rem;
}

.signuPropAccordion .formprop-panel {
  padding: 1rem;
}

/* Common element style */
.bg-gray-50 {
  background-color: var(--gray-50);
}

.property-label {
  margin-bottom: 0.7rem;
  font-size: 0.9rem;
  display: block;
}

.input-field:focus-visible {
  outline: none;
}

.input-field {
  line-height: normal;
  height: 35px;
  font-size: 14px;
  border: 1px solid var(--gray-100);
  width: 100%;
  padding: 6px 12px;
  border-radius: 0.25rem;
}

textarea.input-field {
  height: 94px;
}

/* .input-wrapper {
    padding-bottom: 1.5rem;
} */
.label-m-0 .property-label {
  margin-bottom: 0px;
}

.InputColor_preview {
  min-width: 18px;
  min-height: 18px;
  border-radius: 50%;
  /* position: absolute;
  top: 50%;
  transform: translateY(-50%);
  left: 12px; */
  border: 1px solid rgba(200, 200, 200, 0.45);
}

.color-selector {
  width: 100%;
  max-width: 220px;
  padding-left: 10px;
  border: 1px solid var(--gray-100);
  border-radius: 0.25rem;
}

.color-selector .input-field {
  margin-left: 10px;
  border-width: 0 0 0 1px;
  /* width: 100%; */
  border-radius: 0;
}

.number-field {
  padding-right: 22px;
  padding-left: 11px;
}

.propTypography .input-wrapper.flex .color-selector {
  width: 100%;
}

.input-infobox {
  width: 40px;
  min-width: 40px;
  padding: 6px;
  text-align: center;
  height: 35px;
  border: 1px solid var(--gray-100);
  border-radius: 0 0.25rem 0.25rem 0px;
  line-height: normal;
}

.colorpicker {
  background-color: #fff !important;
  color: #000 !important;
}

/* Toggle Button css */
.ToggleButton {
  cursor: pointer;
  border: 1px solid var(--gray-100);
  padding: 6px 12px;
  background-color: transparent;
  max-width: 194px;
  width: 83px;
  border-radius: 0.25rem;
}

.ToggleButton.selected {
  background-color: var(--primary-color);
  color: #fff;
}

/* input with icon */
.icon {
  background-color: var(--gray-50);
  padding: 9.5px;
  border: 1px solid var(--gray-100);
  border-radius: 0.25rem;
  margin-right: -1px;
}

/* upload image css */
.image-selector {
  margin-top: 1rem;
}

.upload-btn-wrapper {
  position: relative;
  overflow: hidden;
  display: inline-block;
  width: 110px;
}

.upload-btn-wrapper input[type=file] {
  font-size: 100px;
  position: absolute;
  left: 0;
  top: 0;
  opacity: 0;
}

/* border filed */
.borderF-row .number-field {
  border-right: none;
  border-left: none;
  border-radius: 0px;
}

.borderF-row .color-selector {
  border-start-start-radius: 0px;
  border-end-start-radius: 0px;
}

/* property content slide */
.property__component {
  position: fixed;
  width: 411px;
  height: calc(100% - 63px);
  overflow-y: auto;
  right: -411px;
  transition: 0.3s;
  background: #fff;
  z-index: 2;
}

.property__component.open {
  right: 0px;
}

.property-head {
  background-color: #f9f9f9;
  border-bottom: 1px solid var(--gray-100);
}

.slideTitle {
  padding: 1rem;
  color: #000;
  font-weight: 500;
  font-size: 1rem;
  margin-top: 0px;
}

.slideRight {
  color: var(--primary-color);
}

.widgetAction {
  cursor: pointer;
  font-size: 1rem;
  padding: 1rem;
  border-width: 0 1px;
  border-style: solid;
  border-color: var(--gray-100);
  margin-left: -1px;
}

.boxedicon-wrapper {
  border: 1px solid var(--gray-100);
  border-radius: 0.25rem;
  overflow: hidden;
}

.boxedicon {
  cursor: pointer;
  padding: 11px 22px;
  border: none;
  background-color: var(--gray-50);
}

.customSVG {
  padding: 8px 17px;
}

.boxedicon-wrapper .boxedicon:nth-child(2) {
  border-right: 1px solid var(--gray-100);
  border-left: 1px solid var(--gray-100);
}

.boxedicon.selected {
  background-color: var(--primary-color);
  color: #fff;
}

/* widget hightlighted */
.element-hightlighted[builder-element] {
  padding: 2px;
}

[builder-element]:not(.widget) {
  position: relative;
  z-index: 5;
}

.row-container-outer--hover:hover .row-selector:after, .element-hightlighted .row-selector:after, [builder-element].widget--hover:hover:after, [builder-element].element-hightlighted:after, .draggable-source--is-dragging {
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  content: "";
  outline: 2px dashed var(--primary-color);
  outline-offset: -2px;
  z-index: 1;
  background-color: rgba(8, 108, 208, 0.1019607843);
  /* background: rgba(0, 0, 0, 0) ;
  opacity: 1; */
}

.row-container-outer.draggable--over .widget--hover:after, .draggable--over.widget--hover:after {
  display: none;
}

/* .row-draggable.draggable-source--is-dragging .row-selector:after, .widget.draggable-source--is-dragging .widget-content:after {
    background-color: #086cd01a;
} */
/* .element-hightlighted .row-selector:after, .element-hightlighted .row-selector:hover:after{
    background-color: transparent!important;
} */
/* .row-container-outer--hover:hover .row-selector:after {
    z-index: 1;
} */
/* [builder-element].active {
    z-index: 4;
} */
[builder-element].element-hightlighted:after {
  display: none;
}

.element-hightlighted .row-selector:after, [builder-element].element-hightlighted {
  outline: 2px solid var(--primary-color);
  outline-offset: -2px;
  background-color: rgba(8, 108, 208, 0.1019607843);
}

.row_handle, .column_handle {
  display: none;
  cursor: pointer;
  position: absolute;
  top: calc(50% - 15px);
  background-color: #086cd0;
  color: #fff;
  font-size: 1rem;
  border: none;
  cursor: grab;
  width: 27px;
  height: 27px;
  padding: 1px 0px;
}

.row-container-outer--hover:hover .row_handle, .element-hightlighted .row_handle, .widget--hover:hover > .column_handle, .element-hightlighted > .icon-sort {
  display: block;
}

.draggable--over .icon-sort, .widget.draggable-source--is-dragging .icon-sort {
  display: none !important;
}

.row-container-outer--hover:hover .row_handle, .element-hightlighted .row_handle {
  border-radius: 0px 20px 20px 0px;
  left: 0;
  z-index: 3;
  width: 30px;
}

.widget--hover:hover > .column_handle, .element-hightlighted .column_handle {
  border-radius: 20px;
  right: -10px;
  z-index: 15;
}

.draggable-mirror {
  position: fixed;
  z-index: 99999;
  top: 0;
  left: 0;
  pointer-events: none;
  cursor: grab;
  height: 90px !important;
}

.rowMirror {
  width: 304px !important;
}

.widgetMirror {
  width: 104px !important;
}

.mirror-preview {
  opacity: 0.85;
}

.row-container-outer.draggable-source--is-dragging, .widget.draggable-source--is-dragging {
  opacity: 0.8;
  z-index: 6;
}

.widget.draggable-source--is-dragging {
  height: 50px;
}

.row-container-outer.draggable-source--is-dragging {
  height: 150px;
}

.draggable-source--is-dragging .widget-content, .draggable-source--is-dragging .row-container {
  display: none;
}

.row-empty {
  min-height: 40px;
  background-color: var(--primary-light-color);
  text-align: center;
  display: flex;
  justify-content: center;
  align-items: center;
  font-size: 1rem;
  font-weight: 300;
}

.draggable--is-dragging .row-selector {
  display: none;
}

/* [builder-element].active:after {
    display: none;
} */
/* .widget-content:after {
    display: none;
} */