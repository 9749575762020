*,
:after,
:before {
  box-sizing: border-box;
}

:root {
  --generic-border: #ccc;
  --gray-50: #f9fafb;
  --gray-100: #e5e6eb;
  --gray-200: #c8cbd5;
  --gray-300: #8e95a9;
  --gray-400: #717a93;
  --gray-500: #5a6277;
  --gray-600: #444a5b;
  --gray-700: #272d3d;
  --gray-800: #272d3d;
  --gray-900: #171b25;
  --primary-color-200: rgba(8, 108, 208, 0.7);
  --primary-color: #086cd0;
  --primary-light-color: #e6effc;
  --secondary-color: #252525;
  --Poppins-family: "Poppins", Sans-serif;
}

html {
  line-height: 1.15;
  -webkit-text-size-adjust: 100%;
  height: 100%;
}

body {
  font-size: 14px;
  color: #000;
  font-family: var(--Poppins-family);
  margin: 0;
  line-height: normal;
}

figure,
h1,
h2,
h3,
h4,
h5,
h6,
hr,
p,
pre {
  margin: 0;
}

fieldset,
ol,
ul {
  margin: 0;
  padding: 0;
}

ol,
ul {
  list-style: none;
}

h1,
h2,
h3,
h4,
h5,
h6 {
  font-size: inherit;
  font-weight: inherit;
}

.AbrilFatface {
  font-family: "Abril Fatface" !important;
}

.Arial {
  font-family: Arial !important;
}

.Bitter {
  font-family: "Bitter" !important;
}

.Cabin {
  font-family: "Cabin" !important;
}

.CourierNew {
  font-family: "Courier New" !important;
}

.DroidSerif {
  font-family: "Droid Serif" !important;
}

.Georgia {
  font-family: Georgia !important;
}

.HelveticaNeue {
  font-family: "Helvetica Neue" !important;
}

.Lato {
  font-family: "Lato" !important;
}

.LucidaSansUnicode {
  font-family: "Lucida Sans Unicode" !important;
}

.Merriweather {
  font-family: "Merriweather" !important;
}

.Montserrat {
  font-family: "Montserrat" !important;
}

.Nunito {
  font-family: "Nunito" !important;
}

.OpenSans {
  font-family: "Open Sans" !important;
}

.Oswald {
  font-family: "Oswald" !important;
}

.Oxygen {
  font-family: "Oxygen" !important;
}

.Permanent Marker {
  font-family: "Permanent Marker" !important;
}

.Roboto {
  font-family: "Roboto" !important;
}

.RobotoSlab {
  font-family: "Roboto Slab" !important;
}

.SourceSansPro {
  font-family: "Source Sans Pro" !important;
}

.Tahoma {
  font-family: "Tahoma" !important;
}

.TimesNewRoman {
  font-family: "TimesNewRoman" !important;
}

.Trebuchet MS {
  font-family: "Trebuchet MS" !important;
}

.Ubuntu {
  font-family: "Ubuntu" !important;
}

.Verdana {
  font-family: "Verdana" !important;
}

button {
  font-size: inherit;
  font-family: inherit;
}

a {
  color: inherit;
  text-decoration: inherit;
}

.sr-only {
  position: absolute;
  width: 1px;
  height: 1px;
  padding: 0;
  margin: -1px;
  overflow: hidden;
  clip: rect(0, 0, 0, 0);
  white-space: nowrap;
  border-width: 0;
}

/* Hide scrollbar for Chrome, Safari and Opera */
.hide-scrollbar::-webkit-scrollbar {
  display: none;
}

/* Hide scrollbar for IE, Edge and Firefox */
.hide-scrollbar {
  -ms-overflow-style: none; /* IE and Edge */
  scrollbar-width: none; /* Firefox */
}

.text-center {
  text-align: center;
}

.text-right {
  text-align: right;
}

.text-left {
  text-align: left;
}

.cursor-pointer {
  cursor: pointer;
}

.cursor-move {
  cursor: move;
}

.cursor-grab {
  cursor: grab;
}

.transition_class {
  -webkit-transition: all 0.5s;
  -o-transition: all 0.5s;
  -moz-transition: all 0.5s;
  transition: all 0.5s;
}

.img-fluid {
  max-width: 100%;
  height: auto;
}

.bg-center {
  background-position: 50%;
}

.bg-repeat {
  background-repeat: repeat;
}

.bg-no-repeat {
  background-repeat: no-repeat;
}

.bg-repeat-x {
  background-repeat: repeat-x;
}

.bg-repeat-y {
  background-repeat: repeat-y;
}

.bg-repeat-round {
  background-repeat: round;
}

.bg-repeat-space {
  background-repeat: space;
}

.bg-cover {
  background-size: cover;
}

.flex {
  display: flex;
}

.flex-row {
  flex-direction: row;
}

.flex-row-reverse {
  flex-direction: row-reverse;
}

.flex-col {
  flex-direction: column;
}

.flex-col-reverse {
  flex-direction: column-reverse;
}

.flex-wrap {
  flex-wrap: wrap;
}

.flex-wrap-reverse {
  flex-wrap: wrap-reverse;
}

.flex-nowrap {
  flex-wrap: nowrap;
}

.items-start {
  align-items: flex-start;
}

.items-end {
  align-items: flex-end;
}

.items-center {
  align-items: center;
}

.items-baseline {
  align-items: baseline;
}

.items-stretch {
  align-items: stretch;
}

.content-center {
  align-content: center;
}

.content-start {
  align-content: flex-start;
}

.content-end {
  align-content: flex-end;
}

.content-between {
  align-content: space-between;
}

.content-around {
  align-content: space-around;
}

.content-evenly {
  align-content: space-evenly;
}

.self-auto {
  align-self: auto;
}

.self-start {
  align-self: flex-start;
}

.self-end {
  align-self: flex-end;
}

.self-center {
  align-self: center;
}

.self-stretch {
  align-self: stretch;
}

.justify-items-auto {
  justify-items: auto;
}

.justify-items-start {
  justify-items: start;
}

.justify-items-end {
  justify-items: end;
}

.justify-items-center {
  justify-items: center;
}

.justify-items-stretch {
  justify-items: stretch;
}

.justify-start {
  justify-content: flex-start;
}

.justify-end {
  justify-content: flex-end;
}

.justify-center {
  justify-content: center;
}

.justify-between {
  justify-content: space-between;
}

.justify-around {
  justify-content: space-around;
}

.justify-evenly {
  justify-content: space-evenly;
}

.justify-self-auto {
  justify-self: auto;
}

.justify-self-start {
  justify-self: start;
}

.justify-self-end {
  justify-self: end;
}

.justify-self-center {
  justify-self: center;
}

.justify-self-stretch {
  justify-self: stretch;
}

.flex-1 {
  flex: 1 1;
}

.flex-auto {
  flex: 1 1 auto;
}

.flex-initial {
  flex: 0 1 auto;
}

.flex-grow {
  flex-grow: 1;
}

.flex-shrink {
  flex-shrink: 1;
}

.rounded-none {
  border-radius: 0px;
}

.rounded-s-none {
  border-start-start-radius: 0px !important;
  border-end-start-radius: 0px !important;
}

.rounded-e-none {
  border-start-end-radius: 0px;
  border-end-end-radius: 0px;
}

.rounded-r-none {
  border-top-right-radius: 0px;
  border-bottom-right-radius: 0px;
}

.rounded-l-none {
  border-top-left-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-t-none {
  border-top-left-radius: 0px;
  border-top-right-radius: 0px;
}

.rounded-b-none {
  border-bottom-right-radius: 0px;
  border-bottom-left-radius: 0px;
}

.rounded-ss-none {
  border-start-start-radius: 0px;
}

.rounded-se-none {
  border-start-end-radius: 0px;
}

.rounded-ee-none {
  border-end-end-radius: 0px;
}

.rounded-tl-none {
  border-top-left-radius: 0px;
}

.rounded-tr-none {
  border-top-right-radius: 0px;
}

.rounded-br-none {
  border-bottom-right-radius: 0px;
}

.rounded-bl-none {
  border-bottom-left-radius: 0px;
}

.rounded-full {
  border-radius: 50%;
}

.rounded {
  border-radius: 0.25rem;
}

.rounded-md {
  border-radius: 0.375rem;
}

.rounded-lg {
  border-radius: 0.5rem;
}

.rounded-xl {
  border-radius: 0.75rem;
}

.rounded-2xl {
  border-radius: 1rem;
}

.rounded-3xl {
  border-radius: 1.5rem;
}

.border-0 {
  border-width: 0px;
}

.border-t-0 {
  border-top-width: 0px;
}

.border-b-0 {
  border-bottom-width: 0px;
}

.border-r-0 {
  border-right-width: 0px;
}

.border-l-0 {
  border-left-width: 0px;
}

.border-x-0 {
  border-left-width: 0px;
  border-right-width: 0px;
}

.border-y-0 {
  border-top-width: 0px;
  border-bottom-width: 0px;
}

.resize-none {
  resize: none;
}

.resize-y {
  resize: vertical;
}

.resize-x {
  resize: horizontal;
}

.resize {
  resize: both;
}

.overflow-auto {
  overflow: auto;
}

.overflow-hidden {
  overflow: hidden;
}

.overflow-y-auto {
  overflow-y: auto;
}

.object-contain {
  object-fit: contain;
}

.object-cover {
  object-fit: cover;
}

.object-fill {
  object-fit: fill;
}

.object-center {
  object-position: center;
}

.w-full {
  width: 100%;
}

.w-screen {
  width: 100vw;
}

.max-w-full {
  max-width: 100%;
}

.min-w-full {
  min-width: 100%;
}

.h-full {
  height: 100%;
}

.h-screen {
  height: 100vh;
}

.min-h-screen {
  min-height: 100vh;
}

.max-h-full {
  max-height: 100%;
}

/* coloumn width */
.col-md-0\5  {
  width: 4.1666666667%;
}

.col-md-1 {
  width: 8.3333333333%;
}

.col-md-1\.5 {
  width: 12.5%;
}

.col-md-2 {
  width: 16.6666666667%;
}

.col-md-2\.5 {
  width: 20.8333333333%;
}

.col-md-3 {
  width: 25%;
}

.col-md-3\.5 {
  width: 29.1666666667%;
}

.col-md-4 {
  width: 33.3333333333%;
}

.col-md-4\.5 {
  width: 37.5%;
}

.col-md-5 {
  width: 41.6666666667%;
}

.col-md-5\.5 {
  width: 45.8333333333%;
}

.col-md-6 {
  width: 50%;
}

.col-md-6\.5 {
  width: 54.1666666667%;
}

.col-md-7 {
  width: 58.3333333333%;
}

.col-md-7\.5 {
  width: 62.5%;
}

.col-md-8 {
  width: 66.6666666667%;
}

.col-md-8\.5 {
  width: 70.8333333333%;
}

.col-md-9 {
  width: 75%;
}

.col-md-9\.5 {
  width: 79.1666666667%;
}

.col-md-10 {
  width: 83.3333333333%;
}

.col-md-10\.5 {
  width: 87.5%;
}

.col-md-11 {
  width: 91.6666666667%;
}

.col-md-11\.5 {
  width: 95.8333333333%;
}

.col-md-12 {
  width: 100%;
}

.w-0 {
  width: 0px;
}

.w-px {
  width: 1px;
}

.w-0\.5 {
  width: 0.125rem;
}

.w-1 {
  width: 0.25rem;
}

.w-1\.5 {
  width: 0.375rem;
}

.w-2 {
  width: 0.5rem;
}

.w-2\.5 {
  width: 0.625rem;
}

.w-3 {
  width: 0.75rem;
}

.w-3\.5 {
  width: 0.875rem;
}

.w-4 {
  width: 1rem;
}

.w-5 {
  width: 1.25rem;
}

.w-6 {
  width: 1.5rem;
}

.w-7 {
  width: 1.75rem;
}

.w-8 {
  width: 2rem;
}

.w-9 {
  width: 2.25rem;
}

.w-10 {
  width: 2.5rem;
}

.min-w-10 {
  min-width: 2.5rem;
}

.w-11 {
  width: 2.75rem;
}

.min-w-12 {
  min-width: 3rem;
}

.w-12 {
  width: 3rem;
}

.w-14 {
  width: 3.5rem;
}

.w-16 {
  width: 4rem;
}

.w-18 {
  width: 4.5rem;
}

.w-20 {
  width: 5rem;
}

.w-24 {
  width: 6rem;
}

.w-28 {
  width: 7rem;
}

.w-32 {
  width: 8rem;
}

.w-36 {
  width: 9rem;
}

.w-40 {
  width: 10rem;
}

.w-44 {
  width: 11rem;
}

.w-48 {
  width: 12rem;
}

.w-52 {
  width: 13rem;
}

.w-56 {
  width: 14rem;
}

.w-60 {
  width: 15rem;
}

.w-64 {
  width: 16rem;
}

.w-72 {
  width: 18rem;
}

.w-80 {
  width: 20rem;
}

.w-96 {
  width: 24rem;
}

.w-auto {
  width: auto;
}

.h-0 {
  height: 0px;
}

.h-px {
  height: 1px;
}

.h-0\.5 {
  height: 0.125rem;
}

.h-1 {
  height: 0.25rem;
}

.h-1\.5 {
  height: 0.375rem;
}

.h-2 {
  height: 0.5rem;
}

.h-2\.5 {
  height: 0.625rem;
}

.h-3 {
  height: 0.75rem;
}

.h-3\.5 {
  height: 0.875rem;
}

.h-4 {
  height: 1rem;
}

.h-5 {
  height: 1.25rem;
}

.h-6 {
  height: 1.5rem;
}

.h-7 {
  height: 1.75rem;
}

.h-8 {
  height: 2rem;
}

.h-9 {
  height: 2.25rem;
}

.h-10 {
  height: 2.5rem;
}

.h-11 {
  height: 2.75rem;
}

.h-12 {
  height: 3rem;
}

.h-14 {
  height: 3.5rem;
}

.h-16 {
  height: 4rem;
}

.h-18 {
  height: 4.5rem;
}

.h-20 {
  height: 5rem;
}

.h-24 {
  height: 6rem;
}

.h-28 {
  height: 7rem;
}

.h-32 {
  height: 8rem;
}

.h-36 {
  height: 9rem;
}

.h-40 {
  height: 10rem;
}

.h-44 {
  height: 11rem;
}

.h-48 {
  height: 12rem;
}

.h-52 {
  height: 13rem;
}

.h-56 {
  height: 14rem;
}

.h-60 {
  height: 15rem;
}

.h-64 {
  height: 16rem;
}

.h-72 {
  height: 18rem;
}

.h-80 {
  height: 20rem;
}

.h-96 {
  height: 24rem;
}

.h-auto {
  height: auto;
}

.gap-0 {
  gap: 0px;
}

.gap-x-0 {
  column-gap: 0px;
}

.gap-y-0 {
  row-gap: 0px;
}

.gap-px {
  gap: 1px;
}

.gap-x-px {
  column-gap: 1px;
}

.gap-y-px {
  row-gap: 1px;
}

.gap-0\.5 {
  gap: 0.125rem;
}

.gap-x-0\.5 {
  column-gap: 0.125rem;
}

.gap-y-0\.5 {
  row-gap: 0.125rem;
}

.gap-1 {
  gap: 0.25rem;
}

.gap-x-1 {
  column-gap: 0.25rem;
}

.gap-y-1 {
  row-gap: 0.25rem;
}

.gap-1\.5 {
  gap: 0.375rem;
}

.gap-x-1\.5 {
  column-gap: 0.375rem;
}

.gap-y-1\.5 {
  row-gap: 0.375rem;
}

.gap-2 {
  gap: 0.5rem;
}

.gap-x-2 {
  column-gap: 0.5rem;
}

.gap-y-2 {
  row-gap: 0.5rem;
}

.gap-2\.5 {
  gap: 0.625rem;
}

.gap-x-2\.5 {
  column-gap: 0.625rem;
}

.gap-y-2\.5 {
  row-gap: 0.625rem;
}

.gap-3 {
  gap: 0.75rem;
}

.gap-x-3 {
  column-gap: 0.75rem;
}

.gap-y-3 {
  row-gap: 0.75rem;
}

.gap-3\.5 {
  gap: 0.875rem;
}

.gap-x-3\.5 {
  column-gap: 0.875rem;
}

.gap-y-3\.5 {
  row-gap: 0.875rem;
}

.gap-4 {
  gap: 1rem;
}

.gap-x-4 {
  column-gap: 1rem;
}

.gap-y-4 {
  row-gap: 1rem;
}

.gap-5 {
  gap: 1.25rem;
}

.gap-x-5 {
  column-gap: 1.25rem;
}

.gap-y-5 {
  row-gap: 1.25rem;
}

.gap-6 {
  gap: 1.75rem;
}

.gap-x-6 {
  column-gap: 1.75rem;
}

.gap-y-6 {
  row-gap: 1.75rem;
}

.gap-7 {
  gap: 2rem;
}

.gap-x-7 {
  column-gap: 2rem;
}

.gap-y-7 {
  row-gap: 2rem;
}

.gap-8 {
  gap: 2.25rem;
}

.gap-x-8 {
  column-gap: 2.25rem;
}

.gap-y-8 {
  row-gap: 2.25rem;
}

.gap-9 {
  gap: 2.5rem;
}

.gap-x-9 {
  column-gap: 2.5rem;
}

.gap-y-9 {
  row-gap: 2.5rem;
}

.gap-10 {
  gap: 2.5rem;
}

.gap-x-10 {
  column-gap: 2.5rem;
}

.gap-y-10 {
  row-gap: 2.5rem;
}

.gap-11 {
  gap: 2.75rem;
}

.gap-x-11 {
  column-gap: 2.75rem;
}

.gap-y-11 {
  row-gap: 2.75rem;
}

.gap-12 {
  gap: 3rem;
}

.gap-x-12 {
  column-gap: 3rem;
}

.gap-y-12 {
  row-gap: 3rem;
}

.gap-14 {
  gap: 3.5rem;
}

.gap-x-14 {
  column-gap: 3.5rem;
}

.gap-y-14 {
  row-gap: 3.5rem;
}

.gap-16 {
  gap: 4rem;
}

.gap-x-16 {
  column-gap: 4rem;
}

.gap-y-16 {
  row-gap: 4rem;
}

.gap-20 {
  gap: 5rem;
}

.gap-x-20 {
  column-gap: 5rem;
}

.gap-y-20 {
  row-gap: 5rem;
}

.gap-24 {
  gap: 6rem;
}

.gap-x-24 {
  column-gap: 6rem;
}

.gap-y-24 {
  row-gap: 6rem;
}

.gap-28 {
  gap: 7rem;
}

.gap-x-28 {
  column-gap: 7rem;
}

.gap-y-28 {
  row-gap: 7rem;
}

.gap-32 {
  gap: 8rem;
}

.gap-x-32 {
  column-gap: 8rem;
}

.gap-y-32 {
  row-gap: 8rem;
}

.gap-36 {
  gap: 9rem;
}

.gap-x-36 {
  column-gap: 9rem;
}

.gap-y-36 {
  row-gap: 9rem;
}

.gap-40 {
  gap: 10rem;
}

.gap-x-40 {
  column-gap: 10rem;
}

.gap-y-40 {
  row-gap: 10rem;
}

.order-1 {
  order: 1;
}

.order-2 {
  order: 2;
}

.order-3 {
  order: 3;
}

.order-4 {
  order: 4;
}

.order-5 {
  order: 5;
}

.order-6 {
  order: 6;
}

.order-7 {
  order: 7;
}

.order-8 {
  order: 8;
}

.order-9 {
  order: 9;
}

.order-10 {
  order: 10;
}

.order-11 {
  order: 11;
}

.order-12 {
  order: 12;
}

.order-none {
  order: 0;
}

.shadow-sm {
  box-shadow: 0px 1px 3px rgba(39, 45, 61, 0.1), 0px 1px 2px rgba(39, 45, 61, 0.06);
}

.shadow-md {
  box-shadow: 0px 4px 8px -2px rgba(39, 45, 61, 0.1), 0px 2px 4px -2px rgba(39, 45, 61, 0.06);
}

.shadow-lg {
  box-shadow: 0px 12px 16px -4px rgba(39, 45, 61, 0.08), 0px 4px 6px -2px rgba(39, 45, 61, 0.03);
}

.text-xs {
  font-size: 0.75rem;
  line-height: 1rem;
}

.text-sm {
  font-size: 0.875rem;
  line-height: 1.25rem;
}

.text-base {
  font-size: 1rem;
  line-height: 1.5rem;
}

.text-lg {
  font-size: 1.125rem;
  line-height: 1.75rem;
}

.text-xl {
  font-size: 1.25rem;
  line-height: 1.75rem;
}

.text-2xl {
  font-size: 1.5rem;
  line-height: 2rem;
}

.text-3xl {
  font-size: 1.875rem;
  line-height: 2.25rem;
}

.text-4xl {
  font-size: 2.25rem;
  line-height: 2.5rem;
}

.text-5xl {
  font-size: 3rem;
  line-height: 1;
}

.text-6xl {
  font-size: 3.75rem;
  line-height: 1;
}

.text-7xl {
  font-size: 4.5rem;
  line-height: 1;
}

.text-8xl {
  font-size: 6rem;
  line-height: 1;
}

.text-9xl {
  font-size: 8rem;
  line-height: 1;
}

.text-gray-300 {
  color: rgb(209, 213, 219);
}

.hover\:bg-primary-200:hover {
  color: #fff;
  background-color: var(--primary-color-200);
}

.hover\:bg-white:hover {
  color: #000;
  background-color: #fff;
}

.bg-transparent {
  background-color: transparent;
}

.bg-slate-50 {
  background-color: rgb(248, 250, 252);
}

.bg-slate-100 {
  background-color: rgb(241, 245, 249);
}

.bg-slate-200 {
  background-color: rgb(226, 232, 240);
}

.bg-slate-300 {
  background-color: rgb(203, 213, 225);
}

.bg-back {
  background-color: rgb(0, 0, 0);
}

.bg-white {
  background-color: rgb(255, 255, 255);
}

.divider {
  background: var(--gray-200);
}

.vertical {
  width: 1px;
  height: 100%;
}

.horizontal {
  width: 100%;
  height: 1px;
}

.hidden {
  display: none;
}

.inline-block {
  display: inline-block;
}

.grid {
  display: grid;
}

.grid-cols-1 {
  grid-template-columns: repeat(1, minmax(0, 1fr));
}

.grid-cols-2 {
  grid-template-columns: repeat(2, minmax(0, 1fr));
}

.grid-cols-3 {
  grid-template-columns: repeat(3, minmax(0, 1fr));
}

.grid-cols-4 {
  grid-template-columns: repeat(4, minmax(0, 1fr));
}

.grid-cols-5 {
  grid-template-columns: repeat(5, minmax(0, 1fr));
}

.grid-cols-6 {
  grid-template-columns: repeat(6, minmax(0, 1fr));
}

.grid-cols-7 {
  grid-template-columns: repeat(7, minmax(0, 1fr));
}

.grid-cols-8 {
  grid-template-columns: repeat(8, minmax(0, 1fr));
}

.grid-cols-9 {
  grid-template-columns: repeat(9, minmax(0, 1fr));
}

.grid-cols-10 {
  grid-template-columns: repeat(10, minmax(0, 1fr));
}

.grid-cols-11 {
  grid-template-columns: repeat(11, minmax(0, 1fr));
}

.grid-cols-12 {
  grid-template-columns: repeat(12, minmax(0, 1fr));
}

.grid-cols-none {
  grid-template-columns: none;
}

.block {
  display: block;
}

.relative {
  position: relative;
}

.absolute {
  position: absolute;
}

.fixed {
  position: fixed;
}

/* .inset-0 {
  left: 0px;
  right: 0px;
  top: 0px;
  bottom: 0px;
}
.top-0 {
  top: 0px;
}
.right-0 {
  right: 0px;
}
.bottom-0 {
  bottom: 0px;
}
.left-0 {
  left: 0px;
}
.top-auto {
  top: auto;
}
.right-auto {
  right: auto;
}
.bottom-auto {
  bottom: auto;
}
.left-auto {
  left: auto;
}
.inset-x-0 {
  left: 0px;
  right: 0px;
}
.inset-y-0 {
  top: 0px;
  bottom: 0px;
}
.inset-x-auto {
  left: auto;
  right: auto;
}
.inset-y-auto {
  top: auto;
  bottom: auto;
}
.top-50 {
  top: calc(6 / 12 * 100%);
}
.right-50 {
  right: calc(6 / 12 * 100%);
}
.bottom-50 {
  bottom: calc(6 / 12 * 100%);
}
.left-50 {
  left: calc(6 / 12 * 100%);
}
.inset-x-50 {
  left: calc(6 / 12 * 100%);
  right: calc(6 / 12 * 100%);
}
.inset-y-50 {
  top: calc(6 / 12 * 100%);
  bottom: calc(6 / 12 * 100%);
} */
.-mr-px {
  margin-right: -1px;
}

.-ml-px {
  margin-left: -1px;
}

.-mx-px {
  margin-right: -1px;
  margin-left: -1px;
}

.-my-px {
  margin-top: -1px;
  margin-bottom: -1px;
}

.m-0 {
  margin: 0px;
}

.mx-0 {
  margin-left: 0px;
  margin-right: 0px;
}

.my-0 {
  margin-top: 0px;
  margin-bottom: 0px;
}

.mt-0 {
  margin-top: 0px;
}

.mr-0 {
  margin-right: 0px;
}

.mb-0 {
  margin-bottom: 0px;
}

.ml-0 {
  margin-left: 0px;
}

.m-auto {
  margin: auto;
}

.mx-auto {
  margin-left: auto;
  margin-right: auto;
}

.my-auto {
  margin-top: auto;
  margin-bottom: auto;
}

.mt-auto {
  margin-top: auto;
}

.mr-auto {
  margin-right: auto;
}

.mb-auto {
  margin-bottom: auto;
}

.ml-auto {
  margin-left: auto;
}

.m-1 {
  margin: 0.25rem;
}

.mx-1 {
  margin-left: 0.25rem;
  margin-right: 0.25rem;
}

.my-1 {
  margin-top: 0.25rem;
  margin-bottom: 0.25rem;
}

.ms-1 {
  margin-inline-start: 0.25rem;
}

.me-1 {
  margin-inline-end: 0.25rem;
}

.mt-1 {
  margin-top: 0.25rem;
}

.mr-1 {
  margin-right: 0.25rem;
}

.mb-1 {
  margin-bottom: 0.25rem;
}

.ml-1 {
  margin-left: 0.25rem;
}

.m-1\.5 {
  margin: 0.375rem;
}

.mx-1\.5 {
  margin-left: 0.375rem;
  margin-right: 0.375rem;
}

.my-1\.5 {
  margin-top: 0.375rem;
  margin-bottom: 0.375rem;
}

.ms-1\.5 {
  margin-inline-start: 0.375rem;
}

.me-1\.5 {
  margin-inline-end: 0.375rem;
}

.mt-1\.5 {
  margin-top: 0.375rem;
}

.mr-1\.5 {
  margin-right: 0.375rem;
}

.mb-1\.5 {
  margin-bottom: 0.375rem;
}

.ml-1\.5 {
  margin-left: 0.375rem;
}

.m-2 {
  margin: 0.5rem;
}

.mx-2 {
  margin-left: 0.5rem;
  margin-right: 0.5rem;
}

.my-2 {
  margin-top: 0.5rem;
  margin-bottom: 0.5rem;
}

.ms-2 {
  margin-inline-start: 0.5rem;
}

.me-2 {
  margin-inline-end: 0.5rem;
}

.mt-2 {
  margin-top: 0.5rem;
}

.mr-2 {
  margin-right: 0.5rem;
}

.mb-2 {
  margin-bottom: 0.5rem;
}

.ml-2 {
  margin-left: 0.5rem;
}

.m-2\.5 {
  margin: 0.625rem;
}

.mx-2\.5 {
  margin-left: 0.625rem;
  margin-right: 0.625rem;
}

.my-2\.5 {
  margin-top: 0.625rem;
  margin-bottom: 0.625rem;
}

.ms-2\.5 {
  margin-inline-start: 0.625rem;
}

.me-2\.5 {
  margin-inline-end: 0.625rem;
}

.mt-2\.5 {
  margin-top: 0.625rem;
}

.mr-2\.5 {
  margin-right: 0.625rem;
}

.mb-2\.5 {
  margin-bottom: 0.625rem;
}

.ml-2\.5 {
  margin-left: 0.625rem;
}

.m-3 {
  margin: 0.75rem;
}

.mx-3 {
  margin-left: 0.75rem;
  margin-right: 0.75rem;
}

.my-3 {
  margin-top: 0.75rem;
  margin-bottom: 0.75rem;
}

.ms-3 {
  margin-inline-start: 0.75rem;
}

.me-3 {
  margin-inline-end: 0.75rem;
}

.mt-3 {
  margin-top: 0.75rem;
}

.mr-3 {
  margin-right: 0.75rem;
}

.mb-3 {
  margin-bottom: 0.75rem;
}

.ml-3 {
  margin-left: 0.75rem;
}

.m-3\.5 {
  margin: 0.875rem;
}

.mx-3\.5 {
  margin-left: 0.875rem;
  margin-right: 0.875rem;
}

.my-3\.5 {
  margin-top: 0.875rem;
  margin-bottom: 0.875rem;
}

.ms-3\.5 {
  margin-inline-start: 0.875rem;
}

.me-3\.5 {
  margin-inline-end: 0.875rem;
}

.mt-3\.5 {
  margin-top: 0.875rem;
}

.mr-3\.5 {
  margin-right: 0.875rem;
}

.mb-3\.5 {
  margin-bottom: 0.875rem;
}

.ml-3\.5 {
  margin-left: 0.875rem;
}

.m-4 {
  margin: 1rem;
}

.mx-4 {
  margin-left: 1rem;
  margin-right: 1rem;
}

.my-4 {
  margin-top: 1rem;
  margin-bottom: 1rem;
}

.ms-4 {
  margin-inline-start: 1rem;
}

.me-4 {
  margin-inline-end: 1rem;
}

.mt-4 {
  margin-top: 1rem;
}

.-mt-4 {
  margin-top: -1rem;
}

.mr-4 {
  margin-right: 1rem;
}

.mb-4 {
  margin-bottom: 1rem;
}

.ml-4 {
  margin-left: 1rem;
}

.m-5 {
  margin: 1.25rem;
}

.mx-5 {
  margin-left: 1.25rem;
  margin-right: 1.25rem;
}

.my-5 {
  margin-top: 1.25rem;
  margin-bottom: 1.25rem;
}

.mt-5 {
  margin-top: 1.25rem;
}

.mr-5 {
  margin-right: 1.25rem;
}

.mb-5 {
  margin-bottom: 1.25rem;
}

.ml-5 {
  margin-left: 1.25rem;
}

.m-6 {
  margin: 1.5rem;
}

.mx-6 {
  margin-left: 1.5rem;
  margin-right: 1.5rem;
}

.my-6 {
  margin-top: 1.5rem;
  margin-bottom: 1.5rem;
}

.mt-6 {
  margin-top: 1.5rem;
}

.mr-6 {
  margin-right: 1.5rem;
}

.mb-6 {
  margin-bottom: 1.5rem;
}

.ml-6 {
  margin-left: 1.5rem;
}

.m-7 {
  margin: 1.75rem;
}

.mx-7 {
  margin-left: 1.75rem;
  margin-right: 1.75rem;
}

.my-7 {
  margin-top: 1.75rem;
  margin-bottom: 1.75rem;
}

.mt-7 {
  margin-top: 1.75rem;
}

.mr-7 {
  margin-right: 1.75rem;
}

.mb-7 {
  margin-bottom: 1.75rem;
}

.ml-7 {
  margin-left: 1.75rem;
}

.m-8 {
  margin: 2rem;
}

.m-9 {
  margin: 2.25rem;
}

.mx-8 {
  margin-left: 2rem;
  margin-right: 2rem;
}

.my-8 {
  margin-top: 2rem;
  margin-bottom: 2rem;
}

.mx-9 {
  margin-left: 2.25rem;
  margin-right: 2.25rem;
}

.my-9 {
  margin-top: 2.25rem;
  margin-bottom: 2.25rem;
}

.mt-8 {
  margin-top: 2rem;
}

.mr-8 {
  margin-right: 2rem;
}

.mb-8 {
  margin-bottom: 2rem;
}

.ml-8 {
  margin-left: 2rem;
}

.mt-9 {
  margin-top: 2.25rem;
}

.mb-9 {
  margin-bottom: 2.25rem;
}

.mt-12 {
  margin-top: 3rem;
}

.mb-12 {
  margin-top: 3rem;
}

.mr-12 {
  margin-right: 3rem;
}

.ml-12 {
  margin-left: 3rem;
}

.my-12 {
  margin-top: 3rem;
  margin-bottom: 3rem;
}

.mx-12 {
  margin-left: 3rem;
  margin-right: 3rem;
}

.-mx-4 {
  margin-left: -1rem;
  margin-right: -1rem;
}

.-my-4 {
  margin-top: -1rem;
  margin-bottom: -1rem;
}

.p-0 {
  padding: 0px;
}

.px-0 {
  padding-left: 0px;
  padding-right: 0px;
}

.py-0 {
  padding-top: 0px;
  padding-bottom: 0px;
}

.pt-0 {
  padding-top: 0px;
}

.pr-0 {
  padding-right: 0px;
}

.pb-0 {
  padding-bottom: 0px;
}

.pl-0 {
  padding-left: 0px;
}

.p-px {
  padding: 1px;
}

.px-px {
  padding-left: 1px;
  padding-right: 1px;
}

.py-px {
  padding-top: 1px;
  padding-bottom: 1px;
}

.pt-px {
  padding-top: 1px;
}

.pr-px {
  padding-right: 1px;
}

.pb-px {
  padding-bottom: 1px;
}

.pl-px {
  padding-left: 1px;
}

.p-0\.5 {
  padding: 0.125rem;
}

.px-0\.5 {
  padding-left: 0.125rem;
  padding-right: 0.125rem;
}

.py-0\.5 {
  padding-top: 0.125rem;
  padding-bottom: 0.125rem;
}

.pt-0\.5 {
  padding-top: 0.125rem;
}

.pr-0\.5 {
  padding-right: 0.125rem;
}

.pb-0\.5 {
  padding-bottom: 0.125rem;
}

.pl-0\.5 {
  padding-left: 0.125rem;
}

.p-1 {
  padding: 0.25rem;
}

.px-1 {
  padding-left: 0.25rem;
  padding-right: 0.25rem;
}

.py-1 {
  padding-top: 0.25rem;
  padding-bottom: 0.25rem;
}

.pt-1 {
  padding-top: 0.25rem;
}

.pr-1 {
  padding-right: 0.25rem;
}

.pb-1 {
  padding-bottom: 0.25rem;
}

.pl-1 {
  padding-left: 0.25rem;
}

.p-1\.5 {
  padding: 0.375rem;
}

.px-1\.5 {
  padding-left: 0.375rem;
  padding-right: 0.375rem;
}

.py-1\.5 {
  padding-top: 0.375rem;
  padding-bottom: 0.375rem;
}

.pt-1\.5 {
  padding-top: 0.375rem;
}

.pr-1\.5 {
  padding-right: 0.375rem;
}

.pb-1\.5 {
  padding-bottom: 0.375rem;
}

.pl-1\.5 {
  padding-left: 0.375rem;
}

.p-2 {
  padding: 0.5rem;
}

.px-2 {
  padding-left: 0.5rem;
  padding-right: 0.5rem;
}

.py-2 {
  padding-top: 0.5rem;
  padding-bottom: 0.5rem;
}

.pt-2 {
  padding-top: 0.5rem;
}

.pr-2 {
  padding-right: 0.5rem;
}

.pb-2 {
  padding-bottom: 0.5rem;
}

.pl-2 {
  padding-left: 0.5rem;
}

.p-2\.5 {
  padding: 0.625rem;
}

.px-2\.5 {
  padding-left: 0.625rem;
  padding-right: 0.625rem;
}

.py-2\.5 {
  padding-top: 0.625rem;
  padding-bottom: 0.625rem;
}

.pt-2\.5 {
  padding-top: 0.625rem;
}

.pr-2\.5 {
  padding-right: 0.625rem;
}

.pb-2\.5 {
  padding-bottom: 0.625rem;
}

.pl-2\.5 {
  padding-left: 0.625rem;
}

.p-3 {
  padding: 0.75rem;
}

.px-3 {
  padding-left: 0.75rem;
  padding-right: 0.75rem;
}

.py-3 {
  padding-top: 0.75rem;
  padding-bottom: 0.75rem;
}

.pt-3 {
  padding-top: 0.75rem;
}

.pr-3 {
  padding-right: 0.75rem;
}

.pb-3 {
  padding-bottom: 0.75rem;
}

.pl-3 {
  padding-left: 0.75rem;
}

.p-3\.5 {
  padding: 0.875rem;
}

.px-3\.5 {
  padding-left: 0.875rem;
  padding-right: 0.875rem;
}

.py-3\.5 {
  padding-top: 0.875rem;
  padding-bottom: 0.875rem;
}

.pt-3\.5 {
  padding-top: 0.875rem;
}

.pr-3\.5 {
  padding-right: 0.875rem;
}

.pb-3\.5 {
  padding-bottom: 0.875rem;
}

.pl-3\.5 {
  padding-left: 0.875rem;
}

.p-4 {
  padding: 1rem;
}

.px-4 {
  padding-left: 1rem;
  padding-right: 1rem;
}

.-px-4 {
  padding-left: -1rem;
  padding-right: -1rem;
}

.py-4 {
  padding-top: 1rem;
  padding-bottom: 1rem;
}

.-py-4 {
  padding-top: -1rem;
  padding-bottom: -1rem;
}

.pt-4 {
  padding-top: 1rem;
}

.pr-4 {
  padding-right: 1rem;
}

.pb-4 {
  padding-bottom: 1rem;
}

.pl-4 {
  padding-left: 1rem;
}

.p-5 {
  padding: 1.25rem;
}

.px-5 {
  padding-left: 1.25rem;
  padding-right: 1.25rem;
}

.py-5 {
  padding-top: 1.25rem;
  padding-bottom: 1.25rem;
}

.pt-5 {
  padding-top: 1.25rem;
}

.pr-5 {
  padding-right: 1.25rem;
}

.pb-5 {
  padding-bottom: 1.25rem;
}

.pl-5 {
  padding-left: 1.25rem;
}

.p-6 {
  padding: 1.5rem;
}

.px-6 {
  padding-left: 1.5rem;
  padding-right: 1.5rem;
}

.py-6 {
  padding-top: 1.5rem;
  padding-bottom: 1.5rem;
}

.pt-6 {
  padding-top: 1.5rem;
}

.pr-6 {
  padding-right: 1.5rem;
}

.pb-6 {
  padding-bottom: 1.5rem;
}

.pl-6 {
  padding-left: 1.5rem;
}

.p-7 {
  padding: 1.75rem;
}

.px-7 {
  padding-left: 1.75rem;
  padding-right: 1.75rem;
}

.py-7 {
  padding-top: 1.75rem;
  padding-bottom: 1.75rem;
}

.pt-7 {
  padding-top: 1.75rem;
}

.pr-7 {
  padding-right: 1.75rem;
}

.pb-7 {
  padding-bottom: 1.75rem;
}

.pl-7 {
  padding-left: 1.75rem;
}

.p-8 {
  padding: 2rem;
}

.px-8 {
  padding-left: 2rem;
  padding-right: 2rem;
}

.py-8 {
  padding-top: 2rem;
  padding-bottom: 2rem;
}

.pt-8 {
  padding-top: 2rem;
}

.pr-8 {
  padding-right: 2rem;
}

.pb-8 {
  padding-bottom: 2rem;
}

.pl-8 {
  padding-left: 2rem;
}

.p-9 {
  padding: 2.25rem;
}

.px-9 {
  padding-left: 2.25rem;
  padding-right: 2.25rem;
}

.py-9 {
  padding-top: 2.25rem;
  padding-bottom: 2.25rem;
}

.pt-9 {
  padding-top: 2.25rem;
}

.pr-9 {
  padding-right: 2.25rem;
}

.pb-9 {
  padding-bottom: 2.25rem;
}

.pl-9 {
  padding-left: 2.25rem;
}

.p-10 {
  padding: 2.5rem;
}

.px-10 {
  padding-left: 2.5rem;
  padding-right: 2.5rem;
}

.py-10 {
  padding-top: 2.5rem;
  padding-bottom: 2.5rem;
}

.pt-10 {
  padding-top: 2.5rem;
}

.pr-10 {
  padding-right: 2.5rem;
}

.pb-10 {
  padding-bottom: 2.5rem;
}

.pl-10 {
  padding-left: 2.5rem;
}

.p-11 {
  padding: 2.75rem;
}

.px-11 {
  padding-left: 2.75rem;
  padding-right: 2.75rem;
}

.py-11 {
  padding-top: 2.75rem;
  padding-bottom: 2.75rem;
}

.pt-11 {
  padding-top: 2.75rem;
}

.pr-11 {
  padding-right: 2.75rem;
}

.pb-11 {
  padding-bottom: 2.75rem;
}

.pl-11 {
  padding-left: 2.75rem;
}

.p-12 {
  padding: 3rem;
}

.px-12 {
  padding-left: 3rem;
  padding-right: 3rem;
}

.py-12 {
  padding-top: 3rem;
  padding-bottom: 3rem;
}

.pt-12 {
  padding-top: 3rem;
}

.pr-12 {
  padding-right: 3rem;
}

.pb-12 {
  padding-bottom: 3rem;
}

.pl-12 {
  padding-left: 3rem;
}

.p-14 {
  padding: 3.5rem;
}

.px-14 {
  padding-left: 3.5rem;
  padding-right: 3.5rem;
}

.py-14 {
  padding-top: 3.5rem;
  padding-bottom: 3.5rem;
}

.pt-14 {
  padding-top: 3.5rem;
}

.pr-14 {
  padding-right: 3.5rem;
}

.pb-14 {
  padding-bottom: 3.5rem;
}

.pl-14 {
  padding-left: 3.5rem;
}

.p-16 {
  padding: 4rem;
}

.px-16 {
  padding-left: 4rem;
  padding-right: 4rem;
}

.py-16 {
  padding-top: 4rem;
  padding-bottom: 4rem;
}

.pt-16 {
  padding-top: 4rem;
}

.pr-16 {
  padding-right: 4rem;
}

.pb-16 {
  padding-bottom: 4rem;
}

.pl-16 {
  padding-left: 4rem;
}

/* Custom checkbox with switch design */
.custom-checkRadio input {
  -webkit-appearance: none;
  -moz-appearance: none;
  appearance: none;
  cursor: pointer;
  opacity: 0;
  position: absolute;
  width: 100%;
  height: 100%;
  z-index: 1;
  margin: 0;
}

.switch-checbox .checkmark-slider {
  border-radius: 34px;
  background-color: var(--primary-light-color);
  width: 40px;
  height: 20px;
}

.switch-checbox .checkmark-slider:before {
  content: "";
  display: block;
  margin: 2px;
  height: 1rem;
  width: 1rem;
  border-radius: 50%;
  background-color: #ccc;
  -webkit-transform: translateX(2px);
  transform: translateX(2px);
  transition: 0.3s;
}

.switch-checbox.custom-checkRadio input:checked + .checkmark-slider:before {
  -webkit-transform: translateX(18px);
  transform: translateX(18px);
  background-color: #fff;
}

.switch-checbox.custom-checkRadio input:checked + .checkmark-slider {
  background-color: var(--primary-color);
}

/* Create a custom radio button */
.radioStyle .checkmark-slider {
  position: absolute;
  top: 0;
  left: 0;
  height: 100%;
  width: 100%;
  background-color: #fff;
  border: 1px solid #e5e6eb;
  border-radius: 50%;
}

/* On mouse-over, add a grey background color */
.radioStyle:hover input:not(:checked) ~ .checkmark-slider {
  border-color: #949494;
}

/* When the radio button is checked, add a blue background */
.radioStyle input:checked ~ .checkmark-slider {
  background-color: #2f2f2f;
}

/* Create the indicator (the dot/circle - hidden when not checked) */
.radioStyle .checkmark-slider:after {
  content: "";
  position: absolute;
  display: none;
}

/* Show the indicator (dot/circle) when checked */
.radioStyle input:checked ~ .checkmark-slider .radioDot {
  display: block;
}

/* Style the indicator (dot/circle) */
.radioStyle .checkmark-slider .radioDot {
  display: none;
  width: 8px;
  height: 8px;
  border-radius: 50%;
  background: #fff;
  margin: 0 auto;
}

/* Custom checkbox with switch design #e2e8f0 end*/
@media (min-width: 640px) {
  .sm\:block {
    display: block;
  }
  .sm\:hidden {
    display: none;
  }
  .sm\:flex {
    display: flex;
  }
  .sm\:.grid {
    display: grid;
  }
  .sm\:flex-row {
    flex-direction: row;
  }
  .sm\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .sm\:flex-col {
    flex-direction: column;
  }
  .sm\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .sm\:flex-wrap {
    flex-wrap: wrap;
  }
  .sm\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .sm\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .sm\:items-start {
    align-items: flex-start;
  }
  .sm\:items-end {
    align-items: flex-end;
  }
  .sm\:items-center {
    align-items: center;
  }
  .sm\:items-baseline {
    align-items: baseline;
  }
  .sm\:items-stretch {
    align-items: stretch;
  }
  .sm\:content-center {
    align-content: center;
  }
  .sm\:content-start {
    align-content: flex-start;
  }
  .sm\:content-end {
    align-content: flex-end;
  }
  .sm\:content-between {
    align-content: space-between;
  }
  .sm\:content-around {
    align-content: space-around;
  }
  .sm\:content-evenly {
    align-content: space-evenly;
  }
  .sm\:self-auto {
    align-self: auto;
  }
  .sm\:self-start {
    align-self: flex-start;
  }
  .sm\:self-end {
    align-self: flex-end;
  }
  .sm\:self-center {
    align-self: center;
  }
  .sm\:self-stretch {
    align-self: stretch;
  }
  .sm\:justify-items-auto {
    justify-items: auto;
  }
  .sm\:justify-items-start {
    justify-items: start;
  }
  .sm\:justify-items-end {
    justify-items: end;
  }
  .sm\:justify-items-center {
    justify-items: center;
  }
  .sm\:justify-items-stretch {
    justify-items: stretch;
  }
  .sm\:justify-start {
    justify-content: flex-start;
  }
  .sm\:justify-end {
    justify-content: flex-end;
  }
  .sm\:justify-center {
    justify-content: center;
  }
  .sm\:justify-between {
    justify-content: space-between;
  }
  .sm\:justify-around {
    justify-content: space-around;
  }
  .sm\:justify-evenly {
    justify-content: space-evenly;
  }
  .sm\:justify-self-auto {
    justify-self: auto;
  }
  .sm\:justify-self-start {
    justify-self: start;
  }
  .sm\:justify-self-end {
    justify-self: end;
  }
  .sm\:justify-self-center {
    justify-self: center;
  }
  .sm\:justify-self-stretch {
    justify-self: stretch;
  }
  .sm\:flex-1 {
    flex: 1 1;
  }
  .sm\:flex-auto {
    flex: 1 1 auto;
  }
  .sm\:flex-initial {
    flex: 0 1 auto;
  }
  .sm\:flex-grow {
    flex-grow: 1;
  }
  .sm\:flex-shrink {
    flex-shrink: 1;
  }
  .sm\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .sm\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .sm\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .sm\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .sm\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .sm\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .sm\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .sm\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .sm\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .sm\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .sm\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .sm\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .sm\:grid-cols-none {
    grid-template-columns: none;
  }
  /* coloumn width */
  .sm\:col-md-0\5  {
    width: 4.1666666667%;
  }
  .sm\:col-md-1 {
    width: 8.3333333333%;
  }
  .sm\:col-md-1\.5 {
    width: 12.5%;
  }
  .sm\:col-md-2 {
    width: 16.6666666667%;
  }
  .sm\:col-md-2\.5 {
    width: 20.8333333333%;
  }
  .sm\:col-md-3 {
    width: 25%;
  }
  .sm\:col-md-3\.5 {
    width: 29.1666666667%;
  }
  .sm\:col-md-4 {
    width: 33.3333333333%;
  }
  .sm\:col-md-4\.5 {
    width: 37.5%;
  }
  .sm\:col-md-5 {
    width: 41.6666666667%;
  }
  .sm\:col-md-5\.5 {
    width: 45.8333333333%;
  }
  .sm\:col-md-6 {
    width: 50%;
  }
  .sm\:col-md-6\.5 {
    width: 54.1666666667%;
  }
  .sm\:col-md-7 {
    width: 58.3333333333%;
  }
  .sm\:col-md-7\.5 {
    width: 62.5%;
  }
  .sm\:col-md-8 {
    width: 66.6666666667%;
  }
  .sm\:col-md-8\.5 {
    width: 70.8333333333%;
  }
  .sm\:col-md-9 {
    width: 75%;
  }
  .sm\:col-md-9\.5 {
    width: 79.1666666667%;
  }
  .sm\:col-md-10 {
    width: 83.3333333333%;
  }
  .sm\:col-md-10\.5 {
    width: 87.5%;
  }
  .sm\:col-md-11 {
    width: 91.6666666667%;
  }
  .sm\:col-md-11\.5 {
    width: 95.8333333333%;
  }
  .sm\:col-md-12 {
    width: 100%;
  }
  .sm\:gap-0 {
    gap: 0px;
  }
  .sm\:gap-x-0 {
    column-gap: 0px;
  }
  .sm\:gap-y-0 {
    row-gap: 0px;
  }
  .sm\:gap-px {
    gap: 1px;
  }
  .sm\:gap-x-px {
    column-gap: 1px;
  }
  .sm\:gap-y-px {
    row-gap: 1px;
  }
  .sm\:gap-0\.5 {
    gap: 0.125rem;
  }
  .sm\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .sm\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .sm\:gap-1 {
    gap: 0.25rem;
  }
  .sm\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .sm\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .sm\:gap-1\.5 {
    gap: 0.375rem;
  }
  .sm\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .sm\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .sm\:gap-2 {
    gap: 0.5rem;
  }
  .sm\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .sm\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .sm\:gap-2\.5 {
    gap: 0.625rem;
  }
  .sm\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .sm\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .sm\:gap-3 {
    gap: 0.75rem;
  }
  .sm\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .sm\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .sm\:gap-3\.5 {
    gap: 0.875rem;
  }
  .sm\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .sm\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .sm\:gap-4 {
    gap: 1rem;
  }
  .sm\:gap-x-4 {
    column-gap: 1rem;
  }
  .sm\:gap-y-4 {
    row-gap: 1rem;
  }
  .sm\:gap-5 {
    gap: 1.25rem;
  }
  .sm\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .sm\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .sm\:gap-6 {
    gap: 1.75rem;
  }
  .sm\:gap-x-6 {
    column-gap: 1.75rem;
  }
  .sm\:gap-y-6 {
    row-gap: 1.75rem;
  }
  .sm\:gap-7 {
    gap: 2rem;
  }
  .sm\:gap-x-7 {
    column-gap: 2rem;
  }
  .sm\:gap-y-7 {
    row-gap: 2rem;
  }
  .sm\:gap-8 {
    gap: 2.25rem;
  }
  .sm\:gap-x-8 {
    column-gap: 2.25rem;
  }
  .sm\:gap-y-8 {
    row-gap: 2.25rem;
  }
  .sm\:gap-9 {
    gap: 2.5rem;
  }
  .sm\:gap-x-9 {
    column-gap: 2.5rem;
  }
  .sm\:gap-y-9 {
    row-gap: 2.5rem;
  }
  .sm\:gap-10 {
    gap: 2.5rem;
  }
  .sm\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .sm\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .sm\:gap-11 {
    gap: 2.75rem;
  }
  .sm\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .sm\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .sm\:gap-12 {
    gap: 3rem;
  }
  .sm\:gap-x-12 {
    column-gap: 3rem;
  }
  .sm\:gap-y-12 {
    row-gap: 3rem;
  }
  .sm\:gap-14 {
    gap: 3.5rem;
  }
  .sm\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .sm\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .sm\:gap-16 {
    gap: 4rem;
  }
  .sm\:gap-x-16 {
    column-gap: 4rem;
  }
  .sm\:gap-y-16 {
    row-gap: 4rem;
  }
  .sm\:gap-20 {
    gap: 5rem;
  }
  .sm\:gap-x-20 {
    column-gap: 5rem;
  }
  .sm\:gap-y-20 {
    row-gap: 5rem;
  }
  .sm\:gap-24 {
    gap: 6rem;
  }
  .sm\:gap-x-24 {
    column-gap: 6rem;
  }
  .sm\:gap-y-24 {
    row-gap: 6rem;
  }
  .sm\:gap-28 {
    gap: 7rem;
  }
  .sm\:gap-x-28 {
    column-gap: 7rem;
  }
  .sm\:gap-y-28 {
    row-gap: 7rem;
  }
  .sm\:gap-32 {
    gap: 8rem;
  }
  .sm\:gap-x-32 {
    column-gap: 8rem;
  }
  .sm\:gap-y-32 {
    row-gap: 8rem;
  }
  .sm\:gap-36 {
    gap: 9rem;
  }
  .sm\:gap-x-36 {
    column-gap: 9rem;
  }
  .sm\:gap-y-36 {
    row-gap: 9rem;
  }
  .sm\:gap-40 {
    gap: 10rem;
  }
  .sm\:gap-x-40 {
    column-gap: 10rem;
  }
  .sm\:gap-y-40 {
    row-gap: 10rem;
  }
  .sm\:order-1 {
    order: 1;
  }
  .sm\:order-2 {
    order: 2;
  }
  .sm\:order-3 {
    order: 3;
  }
  .sm\:order-4 {
    order: 4;
  }
  .sm\:order-5 {
    order: 5;
  }
  .sm\:order-6 {
    order: 6;
  }
  .sm\:order-7 {
    order: 7;
  }
  .sm\:order-8 {
    order: 8;
  }
  .sm\:order-9 {
    order: 9;
  }
  .sm\:order-10 {
    order: 10;
  }
  .sm\:order-11 {
    order: 11;
  }
  .sm\:order-12 {
    order: 12;
  }
  .sm\:order-none {
    order: 0;
  }
  .sm\:-mr-px {
    margin-right: -1px;
  }
  .sm\:-ml-px {
    margin-left: -1px;
  }
  .sm\:-mx-px {
    margin-right: -1px;
    margin-left: -1px;
  }
  .sm\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .sm\:m-0 {
    margin: 0px;
  }
  .sm\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .sm\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .sm\:mt-0 {
    margin-top: 0px;
  }
  .sm\:mr-0 {
    margin-right: 0px;
  }
  .sm\:mb-0 {
    margin-bottom: 0px;
  }
  .sm\:ml-0 {
    margin-left: 0px;
  }
  .sm\:m-auto {
    margin: auto;
  }
  .sm\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .sm\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .sm\:mt-auto {
    margin-top: auto;
  }
  .sm\:mr-auto {
    margin-right: auto;
  }
  .sm\:mb-auto {
    margin-bottom: auto;
  }
  .sm\:ml-auto {
    margin-left: auto;
  }
  .sm\:m-1 {
    margin: 0.25rem;
  }
  .sm\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .sm\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .sm\:ms-1 {
    margin-inline-start: 0.25rem;
  }
  .sm\:me-1 {
    margin-inline-end: 0.25rem;
  }
  .sm\:mt-1 {
    margin-top: 0.25rem;
  }
  .sm\:mr-1 {
    margin-right: 0.25rem;
  }
  .sm\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .sm\:ml-1 {
    margin-left: 0.25rem;
  }
  .sm\:m-1\.5 {
    margin: 0.375rem;
  }
  .sm\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .sm\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .sm\:ms-1\.5 {
    margin-inline-start: 0.375rem;
  }
  .sm\:me-1\.5 {
    margin-inline-end: 0.375rem;
  }
  .sm\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .sm\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .sm\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .sm\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .sm\:m-2 {
    margin: 0.5rem;
  }
  .sm\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .sm\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .sm\:ms-2 {
    margin-inline-start: 0.5rem;
  }
  .sm\:me-2 {
    margin-inline-end: 0.5rem;
  }
  .sm\:mt-2 {
    margin-top: 0.5rem;
  }
  .sm\:mr-2 {
    margin-right: 0.5rem;
  }
  .sm\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .sm\:ml-2 {
    margin-left: 0.5rem;
  }
  .sm\:m-2\.5 {
    margin: 0.625rem;
  }
  .sm\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .sm\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .sm\:ms-2\.5 {
    margin-inline-start: 0.625rem;
  }
  .sm\:me-2\.5 {
    margin-inline-end: 0.625rem;
  }
  .sm\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .sm\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .sm\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .sm\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .sm\:m-3 {
    margin: 0.75rem;
  }
  .sm\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .sm\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .sm\:ms-3 {
    margin-inline-start: 0.75rem;
  }
  .sm\:me-3 {
    margin-inline-end: 0.75rem;
  }
  .sm\:mt-3 {
    margin-top: 0.75rem;
  }
  .sm\:mr-3 {
    margin-right: 0.75rem;
  }
  .sm\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .sm\:ml-3 {
    margin-left: 0.75rem;
  }
  .sm\:m-3\.5 {
    margin: 0.875rem;
  }
  .sm\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .sm\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .sm\:ms-3\.5 {
    margin-inline-start: 0.875rem;
  }
  .sm\:me-3\.5 {
    margin-inline-end: 0.875rem;
  }
  .sm\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .sm\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .sm\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .sm\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .sm\:m-4 {
    margin: 1rem;
  }
  .sm\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .sm\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .sm\:ms-4 {
    margin-inline-start: 1rem;
  }
  .sm\:me-4 {
    margin-inline-end: 1rem;
  }
  .sm\:mt-4 {
    margin-top: 1rem;
  }
  .sm\:-mt-4 {
    margin-top: -1rem;
  }
  .sm\:mr-4 {
    margin-right: 1rem;
  }
  .sm\:mb-4 {
    margin-bottom: 1rem;
  }
  .sm\:ml-4 {
    margin-left: 1rem;
  }
  .sm\:m-5 {
    margin: 1.25rem;
  }
  .sm\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .sm\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .sm\:mt-5 {
    margin-top: 1.25rem;
  }
  .sm\:mr-5 {
    margin-right: 1.25rem;
  }
  .sm\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .sm\:ml-5 {
    margin-left: 1.25rem;
  }
  .sm\:m-6 {
    margin: 1.5rem;
  }
  .sm\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .sm\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .sm\:mt-6 {
    margin-top: 1.5rem;
  }
  .sm\:mr-6 {
    margin-right: 1.5rem;
  }
  .sm\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .sm\:ml-6 {
    margin-left: 1.5rem;
  }
  .sm\:m-7 {
    margin: 1.75rem;
  }
  .sm\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .sm\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .sm\:mt-7 {
    margin-top: 1.75rem;
  }
  .sm\:mr-7 {
    margin-right: 1.75rem;
  }
  .sm\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .sm\:ml-7 {
    margin-left: 1.75rem;
  }
  .sm\:m-8 {
    margin: 2rem;
  }
  .sm\:m-9 {
    margin: 2.25rem;
  }
  .sm\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .sm\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .sm\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .sm\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .sm\:mt-8 {
    margin-top: 2rem;
  }
  .sm\:mr-8 {
    margin-right: 2rem;
  }
  .sm\:mb-8 {
    margin-bottom: 2rem;
  }
  .sm\:ml-8 {
    margin-left: 2rem;
  }
  .sm\:mt-9 {
    margin-top: 2.25rem;
  }
  .sm\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .sm\:mt-12 {
    margin-top: 3rem;
  }
  .sm\:mb-12 {
    margin-top: 3rem;
  }
  .sm\:mr-12 {
    margin-right: 3rem;
  }
  .sm\:ml-12 {
    margin-left: 3rem;
  }
  .sm\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .sm\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .sm\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .sm\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .sm\:p-0 {
    padding: 0px;
  }
  .sm\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .sm\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .sm\:pt-0 {
    padding-top: 0px;
  }
  .sm\:pr-0 {
    padding-right: 0px;
  }
  .sm\:pb-0 {
    padding-bottom: 0px;
  }
  .sm\:pl-0 {
    padding-left: 0px;
  }
  .sm\:p-px {
    padding: 1px;
  }
  .sm\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .sm\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .sm\:pt-px {
    padding-top: 1px;
  }
  .sm\:pr-px {
    padding-right: 1px;
  }
  .sm\:pb-px {
    padding-bottom: 1px;
  }
  .sm\:pl-px {
    padding-left: 1px;
  }
  .sm\:p-0\.5 {
    padding: 0.125rem;
  }
  .sm\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .sm\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .sm\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .sm\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .sm\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .sm\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .sm\:p-1 {
    padding: 0.25rem;
  }
  .sm\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .sm\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .sm\:pt-1 {
    padding-top: 0.25rem;
  }
  .sm\:pr-1 {
    padding-right: 0.25rem;
  }
  .sm\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .sm\:pl-1 {
    padding-left: 0.25rem;
  }
  .sm\:p-1\.5 {
    padding: 0.375rem;
  }
  .sm\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .sm\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .sm\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .sm\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .sm\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .sm\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .sm\:p-2 {
    padding: 0.5rem;
  }
  .sm\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .sm\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .sm\:pt-2 {
    padding-top: 0.5rem;
  }
  .sm\:pr-2 {
    padding-right: 0.5rem;
  }
  .sm\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .sm\:pl-2 {
    padding-left: 0.5rem;
  }
  .sm\:p-2\.5 {
    padding: 0.625rem;
  }
  .sm\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .sm\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .sm\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .sm\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .sm\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .sm\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .sm\:p-3 {
    padding: 0.75rem;
  }
  .sm\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .sm\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .sm\:pt-3 {
    padding-top: 0.75rem;
  }
  .sm\:pr-3 {
    padding-right: 0.75rem;
  }
  .sm\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .sm\:pl-3 {
    padding-left: 0.75rem;
  }
  .sm\:p-3\.5 {
    padding: 0.875rem;
  }
  .sm\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .sm\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .sm\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .sm\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .sm\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .sm\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .sm\:p-4 {
    padding: 1rem;
  }
  .sm\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .sm\:-px-4 {
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .sm\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .sm\:-py-4 {
    padding-top: -1rem;
    padding-bottom: -1rem;
  }
  .sm\:pt-4 {
    padding-top: 1rem;
  }
  .sm\:pr-4 {
    padding-right: 1rem;
  }
  .sm\:pb-4 {
    padding-bottom: 1rem;
  }
  .sm\:pl-4 {
    padding-left: 1rem;
  }
  .sm\:p-5 {
    padding: 1.25rem;
  }
  .sm\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .sm\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .sm\:pt-5 {
    padding-top: 1.25rem;
  }
  .sm\:pr-5 {
    padding-right: 1.25rem;
  }
  .sm\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .sm\:pl-5 {
    padding-left: 1.25rem;
  }
  .sm\:p-6 {
    padding: 1.5rem;
  }
  .sm\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .sm\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .sm\:pt-6 {
    padding-top: 1.5rem;
  }
  .sm\:pr-6 {
    padding-right: 1.5rem;
  }
  .sm\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .sm\:pl-6 {
    padding-left: 1.5rem;
  }
  .sm\:p-7 {
    padding: 1.75rem;
  }
  .sm\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .sm\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .sm\:pt-7 {
    padding-top: 1.75rem;
  }
  .sm\:pr-7 {
    padding-right: 1.75rem;
  }
  .sm\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .sm\:pl-7 {
    padding-left: 1.75rem;
  }
  .sm\:p-8 {
    padding: 2rem;
  }
  .sm\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .sm\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .sm\:pt-8 {
    padding-top: 2rem;
  }
  .sm\:pr-8 {
    padding-right: 2rem;
  }
  .sm\:pb-8 {
    padding-bottom: 2rem;
  }
  .sm\:pl-8 {
    padding-left: 2rem;
  }
  .sm\:p-9 {
    padding: 2.25rem;
  }
  .sm\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .sm\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .sm\:pt-9 {
    padding-top: 2.25rem;
  }
  .sm\:pr-9 {
    padding-right: 2.25rem;
  }
  .sm\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .sm\:pl-9 {
    padding-left: 2.25rem;
  }
  .sm\:p-10 {
    padding: 2.5rem;
  }
  .sm\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .sm\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .sm\:pt-10 {
    padding-top: 2.5rem;
  }
  .sm\:pr-10 {
    padding-right: 2.5rem;
  }
  .sm\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .sm\:pl-10 {
    padding-left: 2.5rem;
  }
  .sm\:p-11 {
    padding: 2.75rem;
  }
  .sm\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .sm\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .sm\:pt-11 {
    padding-top: 2.75rem;
  }
  .sm\:pr-11 {
    padding-right: 2.75rem;
  }
  .sm\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .sm\:pl-11 {
    padding-left: 2.75rem;
  }
  .sm\:p-12 {
    padding: 3rem;
  }
  .sm\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .sm\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .sm\:pt-12 {
    padding-top: 3rem;
  }
  .sm\:pr-12 {
    padding-right: 3rem;
  }
  .sm\:pb-12 {
    padding-bottom: 3rem;
  }
  .sm\:pl-12 {
    padding-left: 3rem;
  }
  .sm\:p-14 {
    padding: 3.5rem;
  }
  .sm\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .sm\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .sm\:pt-14 {
    padding-top: 3.5rem;
  }
  .sm\:pr-14 {
    padding-right: 3.5rem;
  }
  .sm\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .sm\:pl-14 {
    padding-left: 3.5rem;
  }
  .sm\:p-16 {
    padding: 4rem;
  }
  .sm\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .sm\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .sm\:pt-16 {
    padding-top: 4rem;
  }
  .sm\:pr-16 {
    padding-right: 4rem;
  }
  .sm\:pb-16 {
    padding-bottom: 4rem;
  }
  .sm\:pl-16 {
    padding-left: 4rem;
  }
}
@media (min-width: 768px) {
  .md\:block {
    display: block;
  }
  .md\:hidden {
    display: none;
  }
  .md\:flex {
    display: flex;
  }
  .md\:.grid {
    display: grid;
  }
  .md\:flex-row {
    flex-direction: row;
  }
  .md\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .md\:flex-col {
    flex-direction: column;
  }
  .md\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .md\:flex-wrap {
    flex-wrap: wrap;
  }
  .md\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .md\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .md\:items-start {
    align-items: flex-start;
  }
  .md\:items-end {
    align-items: flex-end;
  }
  .md\:items-center {
    align-items: center;
  }
  .md\:items-baseline {
    align-items: baseline;
  }
  .md\:items-stretch {
    align-items: stretch;
  }
  .md\:content-center {
    align-content: center;
  }
  .md\:content-start {
    align-content: flex-start;
  }
  .md\:content-end {
    align-content: flex-end;
  }
  .md\:content-between {
    align-content: space-between;
  }
  .md\:content-around {
    align-content: space-around;
  }
  .md\:content-evenly {
    align-content: space-evenly;
  }
  .md\:self-auto {
    align-self: auto;
  }
  .md\:self-start {
    align-self: flex-start;
  }
  .md\:self-end {
    align-self: flex-end;
  }
  .md\:self-center {
    align-self: center;
  }
  .md\:self-stretch {
    align-self: stretch;
  }
  .md\:justify-items-auto {
    justify-items: auto;
  }
  .md\:justify-items-start {
    justify-items: start;
  }
  .md\:justify-items-end {
    justify-items: end;
  }
  .md\:justify-items-center {
    justify-items: center;
  }
  .md\:justify-items-stretch {
    justify-items: stretch;
  }
  .md\:justify-start {
    justify-content: flex-start;
  }
  .md\:justify-end {
    justify-content: flex-end;
  }
  .md\:justify-center {
    justify-content: center;
  }
  .md\:justify-between {
    justify-content: space-between;
  }
  .md\:justify-around {
    justify-content: space-around;
  }
  .md\:justify-evenly {
    justify-content: space-evenly;
  }
  .md\:justify-self-auto {
    justify-self: auto;
  }
  .md\:justify-self-start {
    justify-self: start;
  }
  .md\:justify-self-end {
    justify-self: end;
  }
  .md\:justify-self-center {
    justify-self: center;
  }
  .md\:justify-self-stretch {
    justify-self: stretch;
  }
  .md\:flex-1 {
    flex: 1 1;
  }
  .md\:flex-auto {
    flex: 1 1 auto;
  }
  .md\:flex-initial {
    flex: 0 1 auto;
  }
  .md\:flex-grow {
    flex-grow: 1;
  }
  .md\:flex-shrink {
    flex-shrink: 1;
  }
  .md\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .md\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .md\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .md\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .md\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .md\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .md\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .md\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .md\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .md\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .md\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .md\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .md\:grid-cols-none {
    grid-template-columns: none;
  }
  /* coloumn width */
  .md\:col-md-0\5  {
    width: 4.1666666667%;
  }
  .md\:col-md-1 {
    width: 8.3333333333%;
  }
  .md\:col-md-1\.5 {
    width: 12.5%;
  }
  .md\:col-md-2 {
    width: 16.6666666667%;
  }
  .md\:col-md-2\.5 {
    width: 20.8333333333%;
  }
  .md\:col-md-3 {
    width: 25%;
  }
  .md\:col-md-3\.5 {
    width: 29.1666666667%;
  }
  .md\:col-md-4 {
    width: 33.3333333333%;
  }
  .md\:col-md-4\.5 {
    width: 37.5%;
  }
  .md\:col-md-5 {
    width: 41.6666666667%;
  }
  .md\:col-md-5\.5 {
    width: 45.8333333333%;
  }
  .md\:col-md-6 {
    width: 50%;
  }
  .md\:col-md-6\.5 {
    width: 54.1666666667%;
  }
  .md\:col-md-7 {
    width: 58.3333333333%;
  }
  .md\:col-md-7\.5 {
    width: 62.5%;
  }
  .md\:col-md-8 {
    width: 66.6666666667%;
  }
  .md\:col-md-8\.5 {
    width: 70.8333333333%;
  }
  .md\:col-md-9 {
    width: 75%;
  }
  .md\:col-md-9\.5 {
    width: 79.1666666667%;
  }
  .md\:col-md-10 {
    width: 83.3333333333%;
  }
  .md\:col-md-10\.5 {
    width: 87.5%;
  }
  .md\:col-md-11 {
    width: 91.6666666667%;
  }
  .md\:col-md-11\.5 {
    width: 95.8333333333%;
  }
  .md\:col-md-12 {
    width: 100%;
  }
  .md\:gap-0 {
    gap: 0px;
  }
  .md\:gap-x-0 {
    column-gap: 0px;
  }
  .md\:gap-y-0 {
    row-gap: 0px;
  }
  .md\:gap-px {
    gap: 1px;
  }
  .md\:gap-x-px {
    column-gap: 1px;
  }
  .md\:gap-y-px {
    row-gap: 1px;
  }
  .md\:gap-0\.5 {
    gap: 0.125rem;
  }
  .md\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .md\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .md\:gap-1 {
    gap: 0.25rem;
  }
  .md\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .md\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .md\:gap-1\.5 {
    gap: 0.375rem;
  }
  .md\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .md\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .md\:gap-2 {
    gap: 0.5rem;
  }
  .md\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .md\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .md\:gap-2\.5 {
    gap: 0.625rem;
  }
  .md\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .md\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .md\:gap-3 {
    gap: 0.75rem;
  }
  .md\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .md\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .md\:gap-3\.5 {
    gap: 0.875rem;
  }
  .md\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .md\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .md\:gap-4 {
    gap: 1rem;
  }
  .md\:gap-x-4 {
    column-gap: 1rem;
  }
  .md\:gap-y-4 {
    row-gap: 1rem;
  }
  .md\:gap-5 {
    gap: 1.25rem;
  }
  .md\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .md\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .md\:gap-6 {
    gap: 1.75rem;
  }
  .md\:gap-x-6 {
    column-gap: 1.75rem;
  }
  .md\:gap-y-6 {
    row-gap: 1.75rem;
  }
  .md\:gap-7 {
    gap: 2rem;
  }
  .md\:gap-x-7 {
    column-gap: 2rem;
  }
  .md\:gap-y-7 {
    row-gap: 2rem;
  }
  .md\:gap-8 {
    gap: 2.25rem;
  }
  .md\:gap-x-8 {
    column-gap: 2.25rem;
  }
  .md\:gap-y-8 {
    row-gap: 2.25rem;
  }
  .md\:gap-9 {
    gap: 2.5rem;
  }
  .md\:gap-x-9 {
    column-gap: 2.5rem;
  }
  .md\:gap-y-9 {
    row-gap: 2.5rem;
  }
  .md\:gap-10 {
    gap: 2.5rem;
  }
  .md\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .md\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .md\:gap-11 {
    gap: 2.75rem;
  }
  .md\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .md\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .md\:gap-12 {
    gap: 3rem;
  }
  .md\:gap-x-12 {
    column-gap: 3rem;
  }
  .md\:gap-y-12 {
    row-gap: 3rem;
  }
  .md\:gap-14 {
    gap: 3.5rem;
  }
  .md\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .md\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .md\:gap-16 {
    gap: 4rem;
  }
  .md\:gap-x-16 {
    column-gap: 4rem;
  }
  .md\:gap-y-16 {
    row-gap: 4rem;
  }
  .md\:gap-20 {
    gap: 5rem;
  }
  .md\:gap-x-20 {
    column-gap: 5rem;
  }
  .md\:gap-y-20 {
    row-gap: 5rem;
  }
  .md\:gap-24 {
    gap: 6rem;
  }
  .md\:gap-x-24 {
    column-gap: 6rem;
  }
  .md\:gap-y-24 {
    row-gap: 6rem;
  }
  .md\:gap-28 {
    gap: 7rem;
  }
  .md\:gap-x-28 {
    column-gap: 7rem;
  }
  .md\:gap-y-28 {
    row-gap: 7rem;
  }
  .md\:gap-32 {
    gap: 8rem;
  }
  .md\:gap-x-32 {
    column-gap: 8rem;
  }
  .md\:gap-y-32 {
    row-gap: 8rem;
  }
  .md\:gap-36 {
    gap: 9rem;
  }
  .md\:gap-x-36 {
    column-gap: 9rem;
  }
  .md\:gap-y-36 {
    row-gap: 9rem;
  }
  .md\:gap-40 {
    gap: 10rem;
  }
  .md\:gap-x-40 {
    column-gap: 10rem;
  }
  .md\:gap-y-40 {
    row-gap: 10rem;
  }
  .md\:order-1 {
    order: 1;
  }
  .md\:order-2 {
    order: 2;
  }
  .md\:order-3 {
    order: 3;
  }
  .md\:order-4 {
    order: 4;
  }
  .md\:order-5 {
    order: 5;
  }
  .md\:order-6 {
    order: 6;
  }
  .md\:order-7 {
    order: 7;
  }
  .md\:order-8 {
    order: 8;
  }
  .md\:order-9 {
    order: 9;
  }
  .md\:order-10 {
    order: 10;
  }
  .md\:order-11 {
    order: 11;
  }
  .md\:order-12 {
    order: 12;
  }
  .md\:order-none {
    order: 0;
  }
  .md\:-mr-px {
    margin-right: -1px;
  }
  .md\:-ml-px {
    margin-left: -1px;
  }
  .md\:-mx-px {
    margin-right: -1px;
    margin-left: -1px;
  }
  .md\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .md\:m-0 {
    margin: 0px;
  }
  .md\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .md\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .md\:mt-0 {
    margin-top: 0px;
  }
  .md\:mr-0 {
    margin-right: 0px;
  }
  .md\:mb-0 {
    margin-bottom: 0px;
  }
  .md\:ml-0 {
    margin-left: 0px;
  }
  .md\:m-auto {
    margin: auto;
  }
  .md\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .md\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .md\:mt-auto {
    margin-top: auto;
  }
  .md\:mr-auto {
    margin-right: auto;
  }
  .md\:mb-auto {
    margin-bottom: auto;
  }
  .md\:ml-auto {
    margin-left: auto;
  }
  .md\:m-1 {
    margin: 0.25rem;
  }
  .md\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .md\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .md\:ms-1 {
    margin-inline-start: 0.25rem;
  }
  .md\:me-1 {
    margin-inline-end: 0.25rem;
  }
  .md\:mt-1 {
    margin-top: 0.25rem;
  }
  .md\:mr-1 {
    margin-right: 0.25rem;
  }
  .md\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .md\:ml-1 {
    margin-left: 0.25rem;
  }
  .md\:m-1\.5 {
    margin: 0.375rem;
  }
  .md\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .md\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .md\:ms-1\.5 {
    margin-inline-start: 0.375rem;
  }
  .md\:me-1\.5 {
    margin-inline-end: 0.375rem;
  }
  .md\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .md\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .md\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .md\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .md\:m-2 {
    margin: 0.5rem;
  }
  .md\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .md\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .md\:ms-2 {
    margin-inline-start: 0.5rem;
  }
  .md\:me-2 {
    margin-inline-end: 0.5rem;
  }
  .md\:mt-2 {
    margin-top: 0.5rem;
  }
  .md\:mr-2 {
    margin-right: 0.5rem;
  }
  .md\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .md\:ml-2 {
    margin-left: 0.5rem;
  }
  .md\:m-2\.5 {
    margin: 0.625rem;
  }
  .md\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .md\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .md\:ms-2\.5 {
    margin-inline-start: 0.625rem;
  }
  .md\:me-2\.5 {
    margin-inline-end: 0.625rem;
  }
  .md\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .md\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .md\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .md\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .md\:m-3 {
    margin: 0.75rem;
  }
  .md\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .md\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .md\:ms-3 {
    margin-inline-start: 0.75rem;
  }
  .md\:me-3 {
    margin-inline-end: 0.75rem;
  }
  .md\:mt-3 {
    margin-top: 0.75rem;
  }
  .md\:mr-3 {
    margin-right: 0.75rem;
  }
  .md\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .md\:ml-3 {
    margin-left: 0.75rem;
  }
  .md\:m-3\.5 {
    margin: 0.875rem;
  }
  .md\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .md\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .md\:ms-3\.5 {
    margin-inline-start: 0.875rem;
  }
  .md\:me-3\.5 {
    margin-inline-end: 0.875rem;
  }
  .md\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .md\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .md\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .md\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .md\:m-4 {
    margin: 1rem;
  }
  .md\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .md\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .md\:ms-4 {
    margin-inline-start: 1rem;
  }
  .md\:me-4 {
    margin-inline-end: 1rem;
  }
  .md\:mt-4 {
    margin-top: 1rem;
  }
  .md\:-mt-4 {
    margin-top: -1rem;
  }
  .md\:mr-4 {
    margin-right: 1rem;
  }
  .md\:mb-4 {
    margin-bottom: 1rem;
  }
  .md\:ml-4 {
    margin-left: 1rem;
  }
  .md\:m-5 {
    margin: 1.25rem;
  }
  .md\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .md\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .md\:mt-5 {
    margin-top: 1.25rem;
  }
  .md\:mr-5 {
    margin-right: 1.25rem;
  }
  .md\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .md\:ml-5 {
    margin-left: 1.25rem;
  }
  .md\:m-6 {
    margin: 1.5rem;
  }
  .md\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .md\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .md\:mt-6 {
    margin-top: 1.5rem;
  }
  .md\:mr-6 {
    margin-right: 1.5rem;
  }
  .md\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .md\:ml-6 {
    margin-left: 1.5rem;
  }
  .md\:m-7 {
    margin: 1.75rem;
  }
  .md\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .md\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .md\:mt-7 {
    margin-top: 1.75rem;
  }
  .md\:mr-7 {
    margin-right: 1.75rem;
  }
  .md\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .md\:ml-7 {
    margin-left: 1.75rem;
  }
  .md\:m-8 {
    margin: 2rem;
  }
  .md\:m-9 {
    margin: 2.25rem;
  }
  .md\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .md\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .md\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .md\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .md\:mt-8 {
    margin-top: 2rem;
  }
  .md\:mr-8 {
    margin-right: 2rem;
  }
  .md\:mb-8 {
    margin-bottom: 2rem;
  }
  .md\:ml-8 {
    margin-left: 2rem;
  }
  .md\:mt-9 {
    margin-top: 2.25rem;
  }
  .md\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .md\:mt-12 {
    margin-top: 3rem;
  }
  .md\:mb-12 {
    margin-top: 3rem;
  }
  .md\:mr-12 {
    margin-right: 3rem;
  }
  .md\:ml-12 {
    margin-left: 3rem;
  }
  .md\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .md\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .md\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .md\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .md\:p-0 {
    padding: 0px;
  }
  .md\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .md\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .md\:pt-0 {
    padding-top: 0px;
  }
  .md\:pr-0 {
    padding-right: 0px;
  }
  .md\:pb-0 {
    padding-bottom: 0px;
  }
  .md\:pl-0 {
    padding-left: 0px;
  }
  .md\:p-px {
    padding: 1px;
  }
  .md\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .md\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .md\:pt-px {
    padding-top: 1px;
  }
  .md\:pr-px {
    padding-right: 1px;
  }
  .md\:pb-px {
    padding-bottom: 1px;
  }
  .md\:pl-px {
    padding-left: 1px;
  }
  .md\:p-0\.5 {
    padding: 0.125rem;
  }
  .md\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .md\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .md\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .md\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .md\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .md\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .md\:p-1 {
    padding: 0.25rem;
  }
  .md\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .md\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .md\:pt-1 {
    padding-top: 0.25rem;
  }
  .md\:pr-1 {
    padding-right: 0.25rem;
  }
  .md\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .md\:pl-1 {
    padding-left: 0.25rem;
  }
  .md\:p-1\.5 {
    padding: 0.375rem;
  }
  .md\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .md\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .md\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .md\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .md\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .md\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .md\:p-2 {
    padding: 0.5rem;
  }
  .md\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .md\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .md\:pt-2 {
    padding-top: 0.5rem;
  }
  .md\:pr-2 {
    padding-right: 0.5rem;
  }
  .md\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .md\:pl-2 {
    padding-left: 0.5rem;
  }
  .md\:p-2\.5 {
    padding: 0.625rem;
  }
  .md\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .md\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .md\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .md\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .md\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .md\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .md\:p-3 {
    padding: 0.75rem;
  }
  .md\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .md\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .md\:pt-3 {
    padding-top: 0.75rem;
  }
  .md\:pr-3 {
    padding-right: 0.75rem;
  }
  .md\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .md\:pl-3 {
    padding-left: 0.75rem;
  }
  .md\:p-3\.5 {
    padding: 0.875rem;
  }
  .md\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .md\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .md\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .md\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .md\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .md\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .md\:p-4 {
    padding: 1rem;
  }
  .md\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .md\:-px-4 {
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .md\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .md\:-py-4 {
    padding-top: -1rem;
    padding-bottom: -1rem;
  }
  .md\:pt-4 {
    padding-top: 1rem;
  }
  .md\:pr-4 {
    padding-right: 1rem;
  }
  .md\:pb-4 {
    padding-bottom: 1rem;
  }
  .md\:pl-4 {
    padding-left: 1rem;
  }
  .md\:p-5 {
    padding: 1.25rem;
  }
  .md\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .md\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .md\:pt-5 {
    padding-top: 1.25rem;
  }
  .md\:pr-5 {
    padding-right: 1.25rem;
  }
  .md\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .md\:pl-5 {
    padding-left: 1.25rem;
  }
  .md\:p-6 {
    padding: 1.5rem;
  }
  .md\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .md\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .md\:pt-6 {
    padding-top: 1.5rem;
  }
  .md\:pr-6 {
    padding-right: 1.5rem;
  }
  .md\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .md\:pl-6 {
    padding-left: 1.5rem;
  }
  .md\:p-7 {
    padding: 1.75rem;
  }
  .md\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .md\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .md\:pt-7 {
    padding-top: 1.75rem;
  }
  .md\:pr-7 {
    padding-right: 1.75rem;
  }
  .md\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .md\:pl-7 {
    padding-left: 1.75rem;
  }
  .md\:p-8 {
    padding: 2rem;
  }
  .md\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .md\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .md\:pt-8 {
    padding-top: 2rem;
  }
  .md\:pr-8 {
    padding-right: 2rem;
  }
  .md\:pb-8 {
    padding-bottom: 2rem;
  }
  .md\:pl-8 {
    padding-left: 2rem;
  }
  .md\:p-9 {
    padding: 2.25rem;
  }
  .md\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .md\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .md\:pt-9 {
    padding-top: 2.25rem;
  }
  .md\:pr-9 {
    padding-right: 2.25rem;
  }
  .md\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .md\:pl-9 {
    padding-left: 2.25rem;
  }
  .md\:p-10 {
    padding: 2.5rem;
  }
  .md\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .md\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .md\:pt-10 {
    padding-top: 2.5rem;
  }
  .md\:pr-10 {
    padding-right: 2.5rem;
  }
  .md\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .md\:pl-10 {
    padding-left: 2.5rem;
  }
  .md\:p-11 {
    padding: 2.75rem;
  }
  .md\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .md\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .md\:pt-11 {
    padding-top: 2.75rem;
  }
  .md\:pr-11 {
    padding-right: 2.75rem;
  }
  .md\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .md\:pl-11 {
    padding-left: 2.75rem;
  }
  .md\:p-12 {
    padding: 3rem;
  }
  .md\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .md\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .md\:pt-12 {
    padding-top: 3rem;
  }
  .md\:pr-12 {
    padding-right: 3rem;
  }
  .md\:pb-12 {
    padding-bottom: 3rem;
  }
  .md\:pl-12 {
    padding-left: 3rem;
  }
  .md\:p-14 {
    padding: 3.5rem;
  }
  .md\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .md\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .md\:pt-14 {
    padding-top: 3.5rem;
  }
  .md\:pr-14 {
    padding-right: 3.5rem;
  }
  .md\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .md\:pl-14 {
    padding-left: 3.5rem;
  }
  .md\:p-16 {
    padding: 4rem;
  }
  .md\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .md\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .md\:pt-16 {
    padding-top: 4rem;
  }
  .md\:pr-16 {
    padding-right: 4rem;
  }
  .md\:pb-16 {
    padding-bottom: 4rem;
  }
  .md\:pl-16 {
    padding-left: 4rem;
  }
}
@media (min-width: 1024px) {
  .lg\:block {
    display: block;
  }
  .lg\:hidden {
    display: none;
  }
  .lg\:flex {
    display: flex;
  }
  .lg\:.grid {
    display: grid;
  }
  .lg\:flex-row {
    flex-direction: row;
  }
  .lg\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .lg\:flex-col {
    flex-direction: column;
  }
  .lg\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .lg\:flex-wrap {
    flex-wrap: wrap;
  }
  .lg\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .lg\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .lg\:items-start {
    align-items: flex-start;
  }
  .lg\:items-end {
    align-items: flex-end;
  }
  .lg\:items-center {
    align-items: center;
  }
  .lg\:items-baseline {
    align-items: baseline;
  }
  .lg\:items-stretch {
    align-items: stretch;
  }
  .lg\:content-center {
    align-content: center;
  }
  .lg\:content-start {
    align-content: flex-start;
  }
  .lg\:content-end {
    align-content: flex-end;
  }
  .lg\:content-between {
    align-content: space-between;
  }
  .lg\:content-around {
    align-content: space-around;
  }
  .lg\:content-evenly {
    align-content: space-evenly;
  }
  .lg\:self-auto {
    align-self: auto;
  }
  .lg\:self-start {
    align-self: flex-start;
  }
  .lg\:self-end {
    align-self: flex-end;
  }
  .lg\:self-center {
    align-self: center;
  }
  .lg\:self-stretch {
    align-self: stretch;
  }
  .lg\:justify-items-auto {
    justify-items: auto;
  }
  .lg\:justify-items-start {
    justify-items: start;
  }
  .lg\:justify-items-end {
    justify-items: end;
  }
  .lg\:justify-items-center {
    justify-items: center;
  }
  .lg\:justify-items-stretch {
    justify-items: stretch;
  }
  .lg\:justify-start {
    justify-content: flex-start;
  }
  .lg\:justify-end {
    justify-content: flex-end;
  }
  .lg\:justify-center {
    justify-content: center;
  }
  .lg\:justify-between {
    justify-content: space-between;
  }
  .lg\:justify-around {
    justify-content: space-around;
  }
  .lg\:justify-evenly {
    justify-content: space-evenly;
  }
  .lg\:justify-self-auto {
    justify-self: auto;
  }
  .lg\:justify-self-start {
    justify-self: start;
  }
  .lg\:justify-self-end {
    justify-self: end;
  }
  .lg\:justify-self-center {
    justify-self: center;
  }
  .lg\:justify-self-stretch {
    justify-self: stretch;
  }
  .lg\:flex-1 {
    flex: 1 1;
  }
  .lg\:flex-auto {
    flex: 1 1 auto;
  }
  .lg\:flex-initial {
    flex: 0 1 auto;
  }
  .lg\:flex-grow {
    flex-grow: 1;
  }
  .lg\:flex-shrink {
    flex-shrink: 1;
  }
  .lg\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .lg\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .lg\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .lg\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .lg\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .lg\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .lg\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .lg\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .lg\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .lg\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .lg\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .lg\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .lg\:grid-cols-none {
    grid-template-columns: none;
  }
  /* coloumn width */
  .lg\:col-md-0\5  {
    width: 4.1666666667%;
  }
  .lg\:col-md-1 {
    width: 8.3333333333%;
  }
  .lg\:col-md-1\.5 {
    width: 12.5%;
  }
  .lg\:col-md-2 {
    width: 16.6666666667%;
  }
  .lg\:col-md-2\.5 {
    width: 20.8333333333%;
  }
  .lg\:col-md-3 {
    width: 25%;
  }
  .lg\:col-md-3\.5 {
    width: 29.1666666667%;
  }
  .lg\:col-md-4 {
    width: 33.3333333333%;
  }
  .lg\:col-md-4\.5 {
    width: 37.5%;
  }
  .lg\:col-md-5 {
    width: 41.6666666667%;
  }
  .lg\:col-md-5\.5 {
    width: 45.8333333333%;
  }
  .lg\:col-md-6 {
    width: 50%;
  }
  .lg\:col-md-6\.5 {
    width: 54.1666666667%;
  }
  .lg\:col-md-7 {
    width: 58.3333333333%;
  }
  .lg\:col-md-7\.5 {
    width: 62.5%;
  }
  .lg\:col-md-8 {
    width: 66.6666666667%;
  }
  .lg\:col-md-8\.5 {
    width: 70.8333333333%;
  }
  .lg\:col-md-9 {
    width: 75%;
  }
  .lg\:col-md-9\.5 {
    width: 79.1666666667%;
  }
  .lg\:col-md-10 {
    width: 83.3333333333%;
  }
  .lg\:col-md-10\.5 {
    width: 87.5%;
  }
  .lg\:col-md-11 {
    width: 91.6666666667%;
  }
  .lg\:col-md-11\.5 {
    width: 95.8333333333%;
  }
  .lg\:col-md-12 {
    width: 100%;
  }
  .lg\:gap-0 {
    gap: 0px;
  }
  .lg\:gap-x-0 {
    column-gap: 0px;
  }
  .lg\:gap-y-0 {
    row-gap: 0px;
  }
  .lg\:gap-px {
    gap: 1px;
  }
  .lg\:gap-x-px {
    column-gap: 1px;
  }
  .lg\:gap-y-px {
    row-gap: 1px;
  }
  .lg\:gap-0\.5 {
    gap: 0.125rem;
  }
  .lg\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .lg\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .lg\:gap-1 {
    gap: 0.25rem;
  }
  .lg\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .lg\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .lg\:gap-1\.5 {
    gap: 0.375rem;
  }
  .lg\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .lg\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .lg\:gap-2 {
    gap: 0.5rem;
  }
  .lg\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .lg\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .lg\:gap-2\.5 {
    gap: 0.625rem;
  }
  .lg\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .lg\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .lg\:gap-3 {
    gap: 0.75rem;
  }
  .lg\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .lg\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .lg\:gap-3\.5 {
    gap: 0.875rem;
  }
  .lg\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .lg\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .lg\:gap-4 {
    gap: 1rem;
  }
  .lg\:gap-x-4 {
    column-gap: 1rem;
  }
  .lg\:gap-y-4 {
    row-gap: 1rem;
  }
  .lg\:gap-5 {
    gap: 1.25rem;
  }
  .lg\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .lg\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .lg\:gap-6 {
    gap: 1.75rem;
  }
  .lg\:gap-x-6 {
    column-gap: 1.75rem;
  }
  .lg\:gap-y-6 {
    row-gap: 1.75rem;
  }
  .lg\:gap-7 {
    gap: 2rem;
  }
  .lg\:gap-x-7 {
    column-gap: 2rem;
  }
  .lg\:gap-y-7 {
    row-gap: 2rem;
  }
  .lg\:gap-8 {
    gap: 2.25rem;
  }
  .lg\:gap-x-8 {
    column-gap: 2.25rem;
  }
  .lg\:gap-y-8 {
    row-gap: 2.25rem;
  }
  .lg\:gap-9 {
    gap: 2.5rem;
  }
  .lg\:gap-x-9 {
    column-gap: 2.5rem;
  }
  .lg\:gap-y-9 {
    row-gap: 2.5rem;
  }
  .lg\:gap-10 {
    gap: 2.5rem;
  }
  .lg\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .lg\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .lg\:gap-11 {
    gap: 2.75rem;
  }
  .lg\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .lg\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .lg\:gap-12 {
    gap: 3rem;
  }
  .lg\:gap-x-12 {
    column-gap: 3rem;
  }
  .lg\:gap-y-12 {
    row-gap: 3rem;
  }
  .lg\:gap-14 {
    gap: 3.5rem;
  }
  .lg\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .lg\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .lg\:gap-16 {
    gap: 4rem;
  }
  .lg\:gap-x-16 {
    column-gap: 4rem;
  }
  .lg\:gap-y-16 {
    row-gap: 4rem;
  }
  .lg\:gap-20 {
    gap: 5rem;
  }
  .lg\:gap-x-20 {
    column-gap: 5rem;
  }
  .lg\:gap-y-20 {
    row-gap: 5rem;
  }
  .lg\:gap-24 {
    gap: 6rem;
  }
  .lg\:gap-x-24 {
    column-gap: 6rem;
  }
  .lg\:gap-y-24 {
    row-gap: 6rem;
  }
  .lg\:gap-28 {
    gap: 7rem;
  }
  .lg\:gap-x-28 {
    column-gap: 7rem;
  }
  .lg\:gap-y-28 {
    row-gap: 7rem;
  }
  .lg\:gap-32 {
    gap: 8rem;
  }
  .lg\:gap-x-32 {
    column-gap: 8rem;
  }
  .lg\:gap-y-32 {
    row-gap: 8rem;
  }
  .lg\:gap-36 {
    gap: 9rem;
  }
  .lg\:gap-x-36 {
    column-gap: 9rem;
  }
  .lg\:gap-y-36 {
    row-gap: 9rem;
  }
  .lg\:gap-40 {
    gap: 10rem;
  }
  .lg\:gap-x-40 {
    column-gap: 10rem;
  }
  .lg\:gap-y-40 {
    row-gap: 10rem;
  }
  .lg\:order-1 {
    order: 1;
  }
  .lg\:order-2 {
    order: 2;
  }
  .lg\:order-3 {
    order: 3;
  }
  .lg\:order-4 {
    order: 4;
  }
  .lg\:order-5 {
    order: 5;
  }
  .lg\:order-6 {
    order: 6;
  }
  .lg\:order-7 {
    order: 7;
  }
  .lg\:order-8 {
    order: 8;
  }
  .lg\:order-9 {
    order: 9;
  }
  .lg\:order-10 {
    order: 10;
  }
  .lg\:order-11 {
    order: 11;
  }
  .lg\:order-12 {
    order: 12;
  }
  .lg\:order-none {
    order: 0;
  }
  .lg\:-mr-px {
    margin-right: -1px;
  }
  .lg\:-ml-px {
    margin-left: -1px;
  }
  .lg\:-mx-px {
    margin-right: -1px;
    margin-left: -1px;
  }
  .lg\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .lg\:m-0 {
    margin: 0px;
  }
  .lg\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .lg\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .lg\:mt-0 {
    margin-top: 0px;
  }
  .lg\:mr-0 {
    margin-right: 0px;
  }
  .lg\:mb-0 {
    margin-bottom: 0px;
  }
  .lg\:ml-0 {
    margin-left: 0px;
  }
  .lg\:m-auto {
    margin: auto;
  }
  .lg\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .lg\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .lg\:mt-auto {
    margin-top: auto;
  }
  .lg\:mr-auto {
    margin-right: auto;
  }
  .lg\:mb-auto {
    margin-bottom: auto;
  }
  .lg\:ml-auto {
    margin-left: auto;
  }
  .lg\:m-1 {
    margin: 0.25rem;
  }
  .lg\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .lg\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .lg\:ms-1 {
    margin-inline-start: 0.25rem;
  }
  .lg\:me-1 {
    margin-inline-end: 0.25rem;
  }
  .lg\:mt-1 {
    margin-top: 0.25rem;
  }
  .lg\:mr-1 {
    margin-right: 0.25rem;
  }
  .lg\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .lg\:ml-1 {
    margin-left: 0.25rem;
  }
  .lg\:m-1\.5 {
    margin: 0.375rem;
  }
  .lg\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .lg\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .lg\:ms-1\.5 {
    margin-inline-start: 0.375rem;
  }
  .lg\:me-1\.5 {
    margin-inline-end: 0.375rem;
  }
  .lg\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .lg\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .lg\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .lg\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .lg\:m-2 {
    margin: 0.5rem;
  }
  .lg\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .lg\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .lg\:ms-2 {
    margin-inline-start: 0.5rem;
  }
  .lg\:me-2 {
    margin-inline-end: 0.5rem;
  }
  .lg\:mt-2 {
    margin-top: 0.5rem;
  }
  .lg\:mr-2 {
    margin-right: 0.5rem;
  }
  .lg\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .lg\:ml-2 {
    margin-left: 0.5rem;
  }
  .lg\:m-2\.5 {
    margin: 0.625rem;
  }
  .lg\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .lg\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .lg\:ms-2\.5 {
    margin-inline-start: 0.625rem;
  }
  .lg\:me-2\.5 {
    margin-inline-end: 0.625rem;
  }
  .lg\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .lg\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .lg\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .lg\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .lg\:m-3 {
    margin: 0.75rem;
  }
  .lg\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .lg\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .lg\:ms-3 {
    margin-inline-start: 0.75rem;
  }
  .lg\:me-3 {
    margin-inline-end: 0.75rem;
  }
  .lg\:mt-3 {
    margin-top: 0.75rem;
  }
  .lg\:mr-3 {
    margin-right: 0.75rem;
  }
  .lg\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .lg\:ml-3 {
    margin-left: 0.75rem;
  }
  .lg\:m-3\.5 {
    margin: 0.875rem;
  }
  .lg\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .lg\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .lg\:ms-3\.5 {
    margin-inline-start: 0.875rem;
  }
  .lg\:me-3\.5 {
    margin-inline-end: 0.875rem;
  }
  .lg\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .lg\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .lg\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .lg\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .lg\:m-4 {
    margin: 1rem;
  }
  .lg\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .lg\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .lg\:ms-4 {
    margin-inline-start: 1rem;
  }
  .lg\:me-4 {
    margin-inline-end: 1rem;
  }
  .lg\:mt-4 {
    margin-top: 1rem;
  }
  .lg\:-mt-4 {
    margin-top: -1rem;
  }
  .lg\:mr-4 {
    margin-right: 1rem;
  }
  .lg\:mb-4 {
    margin-bottom: 1rem;
  }
  .lg\:ml-4 {
    margin-left: 1rem;
  }
  .lg\:m-5 {
    margin: 1.25rem;
  }
  .lg\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .lg\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .lg\:mt-5 {
    margin-top: 1.25rem;
  }
  .lg\:mr-5 {
    margin-right: 1.25rem;
  }
  .lg\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .lg\:ml-5 {
    margin-left: 1.25rem;
  }
  .lg\:m-6 {
    margin: 1.5rem;
  }
  .lg\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .lg\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .lg\:mt-6 {
    margin-top: 1.5rem;
  }
  .lg\:mr-6 {
    margin-right: 1.5rem;
  }
  .lg\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .lg\:ml-6 {
    margin-left: 1.5rem;
  }
  .lg\:m-7 {
    margin: 1.75rem;
  }
  .lg\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .lg\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .lg\:mt-7 {
    margin-top: 1.75rem;
  }
  .lg\:mr-7 {
    margin-right: 1.75rem;
  }
  .lg\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .lg\:ml-7 {
    margin-left: 1.75rem;
  }
  .lg\:m-8 {
    margin: 2rem;
  }
  .lg\:m-9 {
    margin: 2.25rem;
  }
  .lg\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .lg\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .lg\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .lg\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .lg\:mt-8 {
    margin-top: 2rem;
  }
  .lg\:mr-8 {
    margin-right: 2rem;
  }
  .lg\:mb-8 {
    margin-bottom: 2rem;
  }
  .lg\:ml-8 {
    margin-left: 2rem;
  }
  .lg\:mt-9 {
    margin-top: 2.25rem;
  }
  .lg\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .lg\:mt-12 {
    margin-top: 3rem;
  }
  .lg\:mb-12 {
    margin-top: 3rem;
  }
  .lg\:mr-12 {
    margin-right: 3rem;
  }
  .lg\:ml-12 {
    margin-left: 3rem;
  }
  .lg\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .lg\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .lg\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .lg\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .lg\:p-0 {
    padding: 0px;
  }
  .lg\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .lg\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .lg\:pt-0 {
    padding-top: 0px;
  }
  .lg\:pr-0 {
    padding-right: 0px;
  }
  .lg\:pb-0 {
    padding-bottom: 0px;
  }
  .lg\:pl-0 {
    padding-left: 0px;
  }
  .lg\:p-px {
    padding: 1px;
  }
  .lg\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .lg\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .lg\:pt-px {
    padding-top: 1px;
  }
  .lg\:pr-px {
    padding-right: 1px;
  }
  .lg\:pb-px {
    padding-bottom: 1px;
  }
  .lg\:pl-px {
    padding-left: 1px;
  }
  .lg\:p-0\.5 {
    padding: 0.125rem;
  }
  .lg\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .lg\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .lg\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .lg\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .lg\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .lg\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .lg\:p-1 {
    padding: 0.25rem;
  }
  .lg\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .lg\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .lg\:pt-1 {
    padding-top: 0.25rem;
  }
  .lg\:pr-1 {
    padding-right: 0.25rem;
  }
  .lg\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .lg\:pl-1 {
    padding-left: 0.25rem;
  }
  .lg\:p-1\.5 {
    padding: 0.375rem;
  }
  .lg\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .lg\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .lg\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .lg\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .lg\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .lg\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .lg\:p-2 {
    padding: 0.5rem;
  }
  .lg\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .lg\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .lg\:pt-2 {
    padding-top: 0.5rem;
  }
  .lg\:pr-2 {
    padding-right: 0.5rem;
  }
  .lg\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .lg\:pl-2 {
    padding-left: 0.5rem;
  }
  .lg\:p-2\.5 {
    padding: 0.625rem;
  }
  .lg\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .lg\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .lg\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .lg\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .lg\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .lg\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .lg\:p-3 {
    padding: 0.75rem;
  }
  .lg\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .lg\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .lg\:pt-3 {
    padding-top: 0.75rem;
  }
  .lg\:pr-3 {
    padding-right: 0.75rem;
  }
  .lg\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .lg\:pl-3 {
    padding-left: 0.75rem;
  }
  .lg\:p-3\.5 {
    padding: 0.875rem;
  }
  .lg\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .lg\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .lg\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .lg\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .lg\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .lg\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .lg\:p-4 {
    padding: 1rem;
  }
  .lg\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .lg\:-px-4 {
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .lg\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .lg\:-py-4 {
    padding-top: -1rem;
    padding-bottom: -1rem;
  }
  .lg\:pt-4 {
    padding-top: 1rem;
  }
  .lg\:pr-4 {
    padding-right: 1rem;
  }
  .lg\:pb-4 {
    padding-bottom: 1rem;
  }
  .lg\:pl-4 {
    padding-left: 1rem;
  }
  .lg\:p-5 {
    padding: 1.25rem;
  }
  .lg\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .lg\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .lg\:pt-5 {
    padding-top: 1.25rem;
  }
  .lg\:pr-5 {
    padding-right: 1.25rem;
  }
  .lg\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .lg\:pl-5 {
    padding-left: 1.25rem;
  }
  .lg\:p-6 {
    padding: 1.5rem;
  }
  .lg\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .lg\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .lg\:pt-6 {
    padding-top: 1.5rem;
  }
  .lg\:pr-6 {
    padding-right: 1.5rem;
  }
  .lg\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .lg\:pl-6 {
    padding-left: 1.5rem;
  }
  .lg\:p-7 {
    padding: 1.75rem;
  }
  .lg\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .lg\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .lg\:pt-7 {
    padding-top: 1.75rem;
  }
  .lg\:pr-7 {
    padding-right: 1.75rem;
  }
  .lg\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .lg\:pl-7 {
    padding-left: 1.75rem;
  }
  .lg\:p-8 {
    padding: 2rem;
  }
  .lg\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .lg\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .lg\:pt-8 {
    padding-top: 2rem;
  }
  .lg\:pr-8 {
    padding-right: 2rem;
  }
  .lg\:pb-8 {
    padding-bottom: 2rem;
  }
  .lg\:pl-8 {
    padding-left: 2rem;
  }
  .lg\:p-9 {
    padding: 2.25rem;
  }
  .lg\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .lg\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .lg\:pt-9 {
    padding-top: 2.25rem;
  }
  .lg\:pr-9 {
    padding-right: 2.25rem;
  }
  .lg\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .lg\:pl-9 {
    padding-left: 2.25rem;
  }
  .lg\:p-10 {
    padding: 2.5rem;
  }
  .lg\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .lg\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .lg\:pt-10 {
    padding-top: 2.5rem;
  }
  .lg\:pr-10 {
    padding-right: 2.5rem;
  }
  .lg\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .lg\:pl-10 {
    padding-left: 2.5rem;
  }
  .lg\:p-11 {
    padding: 2.75rem;
  }
  .lg\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .lg\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .lg\:pt-11 {
    padding-top: 2.75rem;
  }
  .lg\:pr-11 {
    padding-right: 2.75rem;
  }
  .lg\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .lg\:pl-11 {
    padding-left: 2.75rem;
  }
  .lg\:p-12 {
    padding: 3rem;
  }
  .lg\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .lg\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .lg\:pt-12 {
    padding-top: 3rem;
  }
  .lg\:pr-12 {
    padding-right: 3rem;
  }
  .lg\:pb-12 {
    padding-bottom: 3rem;
  }
  .lg\:pl-12 {
    padding-left: 3rem;
  }
  .lg\:p-14 {
    padding: 3.5rem;
  }
  .lg\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .lg\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .lg\:pt-14 {
    padding-top: 3.5rem;
  }
  .lg\:pr-14 {
    padding-right: 3.5rem;
  }
  .lg\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .lg\:pl-14 {
    padding-left: 3.5rem;
  }
  .lg\:p-16 {
    padding: 4rem;
  }
  .lg\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .lg\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .lg\:pt-16 {
    padding-top: 4rem;
  }
  .lg\:pr-16 {
    padding-right: 4rem;
  }
  .lg\:pb-16 {
    padding-bottom: 4rem;
  }
  .lg\:pl-16 {
    padding-left: 4rem;
  }
}
@media (min-width: 1280px) {
  .xl\:block {
    display: block;
  }
  .xl\:hidden {
    display: none;
  }
  .xl\:flex {
    display: flex;
  }
  .xl\:.grid {
    display: grid;
  }
  .xl\:flex-row {
    flex-direction: row;
  }
  .xl\:flex-row-reverse {
    flex-direction: row-reverse;
  }
  .xl\:flex-col {
    flex-direction: column;
  }
  .xl\:flex-col-reverse {
    flex-direction: column-reverse;
  }
  .xl\:flex-wrap {
    flex-wrap: wrap;
  }
  .xl\:flex-wrap-reverse {
    flex-wrap: wrap-reverse;
  }
  .xl\:flex-nowrap {
    flex-wrap: nowrap;
  }
  .xl\:items-start {
    align-items: flex-start;
  }
  .xl\:items-end {
    align-items: flex-end;
  }
  .xl\:items-center {
    align-items: center;
  }
  .xl\:items-baseline {
    align-items: baseline;
  }
  .xl\:items-stretch {
    align-items: stretch;
  }
  .xl\:content-center {
    align-content: center;
  }
  .xl\:content-start {
    align-content: flex-start;
  }
  .xl\:content-end {
    align-content: flex-end;
  }
  .xl\:content-between {
    align-content: space-between;
  }
  .xl\:content-around {
    align-content: space-around;
  }
  .xl\:content-evenly {
    align-content: space-evenly;
  }
  .xl\:self-auto {
    align-self: auto;
  }
  .xl\:self-start {
    align-self: flex-start;
  }
  .xl\:self-end {
    align-self: flex-end;
  }
  .xl\:self-center {
    align-self: center;
  }
  .xl\:self-stretch {
    align-self: stretch;
  }
  .xl\:justify-items-auto {
    justify-items: auto;
  }
  .xl\:justify-items-start {
    justify-items: start;
  }
  .xl\:justify-items-end {
    justify-items: end;
  }
  .xl\:justify-items-center {
    justify-items: center;
  }
  .xl\:justify-items-stretch {
    justify-items: stretch;
  }
  .xl\:justify-start {
    justify-content: flex-start;
  }
  .xl\:justify-end {
    justify-content: flex-end;
  }
  .xl\:justify-center {
    justify-content: center;
  }
  .xl\:justify-between {
    justify-content: space-between;
  }
  .xl\:justify-around {
    justify-content: space-around;
  }
  .xl\:justify-evenly {
    justify-content: space-evenly;
  }
  .xl\:justify-self-auto {
    justify-self: auto;
  }
  .xl\:justify-self-start {
    justify-self: start;
  }
  .xl\:justify-self-end {
    justify-self: end;
  }
  .xl\:justify-self-center {
    justify-self: center;
  }
  .xl\:justify-self-stretch {
    justify-self: stretch;
  }
  .xl\:flex-1 {
    flex: 1 1;
  }
  .xl\:flex-auto {
    flex: 1 1 auto;
  }
  .xl\:flex-initial {
    flex: 0 1 auto;
  }
  .xl\:flex-grow {
    flex-grow: 1;
  }
  .xl\:flex-shrink {
    flex-shrink: 1;
  }
  .xl\:grid-cols-1 {
    grid-template-columns: repeat(1, minmax(0, 1fr));
  }
  .xl\:grid-cols-2 {
    grid-template-columns: repeat(2, minmax(0, 1fr));
  }
  .xl\:grid-cols-3 {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
  .xl\:grid-cols-4 {
    grid-template-columns: repeat(4, minmax(0, 1fr));
  }
  .xl\:grid-cols-5 {
    grid-template-columns: repeat(5, minmax(0, 1fr));
  }
  .xl\:grid-cols-6 {
    grid-template-columns: repeat(6, minmax(0, 1fr));
  }
  .xl\:grid-cols-7 {
    grid-template-columns: repeat(7, minmax(0, 1fr));
  }
  .xl\:grid-cols-8 {
    grid-template-columns: repeat(8, minmax(0, 1fr));
  }
  .xl\:grid-cols-9 {
    grid-template-columns: repeat(9, minmax(0, 1fr));
  }
  .xl\:grid-cols-10 {
    grid-template-columns: repeat(10, minmax(0, 1fr));
  }
  .xl\:grid-cols-11 {
    grid-template-columns: repeat(11, minmax(0, 1fr));
  }
  .xl\:grid-cols-12 {
    grid-template-columns: repeat(12, minmax(0, 1fr));
  }
  .xl\:grid-cols-none {
    grid-template-columns: none;
  }
  /* coloumn width */
  .xl\:col-md-0\5  {
    width: 4.1666666667%;
  }
  .xl\:col-md-1 {
    width: 8.3333333333%;
  }
  .xl\:col-md-1\.5 {
    width: 12.5%;
  }
  .xl\:col-md-2 {
    width: 16.6666666667%;
  }
  .xl\:col-md-2\.5 {
    width: 20.8333333333%;
  }
  .xl\:col-md-3 {
    width: 25%;
  }
  .xl\:col-md-3\.5 {
    width: 29.1666666667%;
  }
  .xl\:col-md-4 {
    width: 33.3333333333%;
  }
  .xl\:col-md-4\.5 {
    width: 37.5%;
  }
  .xl\:col-md-5 {
    width: 41.6666666667%;
  }
  .xl\:col-md-5\.5 {
    width: 45.8333333333%;
  }
  .xl\:col-md-6 {
    width: 50%;
  }
  .xl\:col-md-6\.5 {
    width: 54.1666666667%;
  }
  .xl\:col-md-7 {
    width: 58.3333333333%;
  }
  .xl\:col-md-7\.5 {
    width: 62.5%;
  }
  .xl\:col-md-8 {
    width: 66.6666666667%;
  }
  .xl\:col-md-8\.5 {
    width: 70.8333333333%;
  }
  .xl\:col-md-9 {
    width: 75%;
  }
  .xl\:col-md-9\.5 {
    width: 79.1666666667%;
  }
  .xl\:col-md-10 {
    width: 83.3333333333%;
  }
  .xl\:col-md-10\.5 {
    width: 87.5%;
  }
  .xl\:col-md-11 {
    width: 91.6666666667%;
  }
  .xl\:col-md-11\.5 {
    width: 95.8333333333%;
  }
  .xl\:col-md-12 {
    width: 100%;
  }
  .xl\:gap-0 {
    gap: 0px;
  }
  .xl\:gap-x-0 {
    column-gap: 0px;
  }
  .xl\:gap-y-0 {
    row-gap: 0px;
  }
  .xl\:gap-px {
    gap: 1px;
  }
  .xl\:gap-x-px {
    column-gap: 1px;
  }
  .xl\:gap-y-px {
    row-gap: 1px;
  }
  .xl\:gap-0\.5 {
    gap: 0.125rem;
  }
  .xl\:gap-x-0\.5 {
    column-gap: 0.125rem;
  }
  .xl\:gap-y-0\.5 {
    row-gap: 0.125rem;
  }
  .xl\:gap-1 {
    gap: 0.25rem;
  }
  .xl\:gap-x-1 {
    column-gap: 0.25rem;
  }
  .xl\:gap-y-1 {
    row-gap: 0.25rem;
  }
  .xl\:gap-1\.5 {
    gap: 0.375rem;
  }
  .xl\:gap-x-1\.5 {
    column-gap: 0.375rem;
  }
  .xl\:gap-y-1\.5 {
    row-gap: 0.375rem;
  }
  .xl\:gap-2 {
    gap: 0.5rem;
  }
  .xl\:gap-x-2 {
    column-gap: 0.5rem;
  }
  .xl\:gap-y-2 {
    row-gap: 0.5rem;
  }
  .xl\:gap-2\.5 {
    gap: 0.625rem;
  }
  .xl\:gap-x-2\.5 {
    column-gap: 0.625rem;
  }
  .xl\:gap-y-2\.5 {
    row-gap: 0.625rem;
  }
  .xl\:gap-3 {
    gap: 0.75rem;
  }
  .xl\:gap-x-3 {
    column-gap: 0.75rem;
  }
  .xl\:gap-y-3 {
    row-gap: 0.75rem;
  }
  .xl\:gap-3\.5 {
    gap: 0.875rem;
  }
  .xl\:gap-x-3\.5 {
    column-gap: 0.875rem;
  }
  .xl\:gap-y-3\.5 {
    row-gap: 0.875rem;
  }
  .xl\:gap-4 {
    gap: 1rem;
  }
  .xl\:gap-x-4 {
    column-gap: 1rem;
  }
  .xl\:gap-y-4 {
    row-gap: 1rem;
  }
  .xl\:gap-5 {
    gap: 1.25rem;
  }
  .xl\:gap-x-5 {
    column-gap: 1.25rem;
  }
  .xl\:gap-y-5 {
    row-gap: 1.25rem;
  }
  .xl\:gap-6 {
    gap: 1.75rem;
  }
  .xl\:gap-x-6 {
    column-gap: 1.75rem;
  }
  .xl\:gap-y-6 {
    row-gap: 1.75rem;
  }
  .xl\:gap-7 {
    gap: 2rem;
  }
  .xl\:gap-x-7 {
    column-gap: 2rem;
  }
  .xl\:gap-y-7 {
    row-gap: 2rem;
  }
  .xl\:gap-8 {
    gap: 2.25rem;
  }
  .xl\:gap-x-8 {
    column-gap: 2.25rem;
  }
  .xl\:gap-y-8 {
    row-gap: 2.25rem;
  }
  .xl\:gap-9 {
    gap: 2.5rem;
  }
  .xl\:gap-x-9 {
    column-gap: 2.5rem;
  }
  .xl\:gap-y-9 {
    row-gap: 2.5rem;
  }
  .xl\:gap-10 {
    gap: 2.5rem;
  }
  .xl\:gap-x-10 {
    column-gap: 2.5rem;
  }
  .xl\:gap-y-10 {
    row-gap: 2.5rem;
  }
  .xl\:gap-11 {
    gap: 2.75rem;
  }
  .xl\:gap-x-11 {
    column-gap: 2.75rem;
  }
  .xl\:gap-y-11 {
    row-gap: 2.75rem;
  }
  .xl\:gap-12 {
    gap: 3rem;
  }
  .xl\:gap-x-12 {
    column-gap: 3rem;
  }
  .xl\:gap-y-12 {
    row-gap: 3rem;
  }
  .xl\:gap-14 {
    gap: 3.5rem;
  }
  .xl\:gap-x-14 {
    column-gap: 3.5rem;
  }
  .xl\:gap-y-14 {
    row-gap: 3.5rem;
  }
  .xl\:gap-16 {
    gap: 4rem;
  }
  .xl\:gap-x-16 {
    column-gap: 4rem;
  }
  .xl\:gap-y-16 {
    row-gap: 4rem;
  }
  .xl\:gap-20 {
    gap: 5rem;
  }
  .xl\:gap-x-20 {
    column-gap: 5rem;
  }
  .xl\:gap-y-20 {
    row-gap: 5rem;
  }
  .xl\:gap-24 {
    gap: 6rem;
  }
  .xl\:gap-x-24 {
    column-gap: 6rem;
  }
  .xl\:gap-y-24 {
    row-gap: 6rem;
  }
  .xl\:gap-28 {
    gap: 7rem;
  }
  .xl\:gap-x-28 {
    column-gap: 7rem;
  }
  .xl\:gap-y-28 {
    row-gap: 7rem;
  }
  .xl\:gap-32 {
    gap: 8rem;
  }
  .xl\:gap-x-32 {
    column-gap: 8rem;
  }
  .xl\:gap-y-32 {
    row-gap: 8rem;
  }
  .xl\:gap-36 {
    gap: 9rem;
  }
  .xl\:gap-x-36 {
    column-gap: 9rem;
  }
  .xl\:gap-y-36 {
    row-gap: 9rem;
  }
  .xl\:gap-40 {
    gap: 10rem;
  }
  .xl\:gap-x-40 {
    column-gap: 10rem;
  }
  .xl\:gap-y-40 {
    row-gap: 10rem;
  }
  .xl\:order-1 {
    order: 1;
  }
  .xl\:order-2 {
    order: 2;
  }
  .xl\:order-3 {
    order: 3;
  }
  .xl\:order-4 {
    order: 4;
  }
  .xl\:order-5 {
    order: 5;
  }
  .xl\:order-6 {
    order: 6;
  }
  .xl\:order-7 {
    order: 7;
  }
  .xl\:order-8 {
    order: 8;
  }
  .xl\:order-9 {
    order: 9;
  }
  .xl\:order-10 {
    order: 10;
  }
  .xl\:order-11 {
    order: 11;
  }
  .xl\:order-12 {
    order: 12;
  }
  .xl\:order-none {
    order: 0;
  }
  .xl\:-mr-px {
    margin-right: -1px;
  }
  .xl\:-ml-px {
    margin-left: -1px;
  }
  .xl\:-mx-px {
    margin-right: -1px;
    margin-left: -1px;
  }
  .xl\:-my-px {
    margin-top: -1px;
    margin-bottom: -1px;
  }
  .xl\:m-0 {
    margin: 0px;
  }
  .xl\:mx-0 {
    margin-left: 0px;
    margin-right: 0px;
  }
  .xl\:my-0 {
    margin-top: 0px;
    margin-bottom: 0px;
  }
  .xl\:mt-0 {
    margin-top: 0px;
  }
  .xl\:mr-0 {
    margin-right: 0px;
  }
  .xl\:mb-0 {
    margin-bottom: 0px;
  }
  .xl\:ml-0 {
    margin-left: 0px;
  }
  .xl\:m-auto {
    margin: auto;
  }
  .xl\:mx-auto {
    margin-left: auto;
    margin-right: auto;
  }
  .xl\:my-auto {
    margin-top: auto;
    margin-bottom: auto;
  }
  .xl\:mt-auto {
    margin-top: auto;
  }
  .xl\:mr-auto {
    margin-right: auto;
  }
  .xl\:mb-auto {
    margin-bottom: auto;
  }
  .xl\:ml-auto {
    margin-left: auto;
  }
  .xl\:m-1 {
    margin: 0.25rem;
  }
  .xl\:mx-1 {
    margin-left: 0.25rem;
    margin-right: 0.25rem;
  }
  .xl\:my-1 {
    margin-top: 0.25rem;
    margin-bottom: 0.25rem;
  }
  .xl\:ms-1 {
    margin-inline-start: 0.25rem;
  }
  .xl\:me-1 {
    margin-inline-end: 0.25rem;
  }
  .xl\:mt-1 {
    margin-top: 0.25rem;
  }
  .xl\:mr-1 {
    margin-right: 0.25rem;
  }
  .xl\:mb-1 {
    margin-bottom: 0.25rem;
  }
  .xl\:ml-1 {
    margin-left: 0.25rem;
  }
  .xl\:m-1\.5 {
    margin: 0.375rem;
  }
  .xl\:mx-1\.5 {
    margin-left: 0.375rem;
    margin-right: 0.375rem;
  }
  .xl\:my-1\.5 {
    margin-top: 0.375rem;
    margin-bottom: 0.375rem;
  }
  .xl\:ms-1\.5 {
    margin-inline-start: 0.375rem;
  }
  .xl\:me-1\.5 {
    margin-inline-end: 0.375rem;
  }
  .xl\:mt-1\.5 {
    margin-top: 0.375rem;
  }
  .xl\:mr-1\.5 {
    margin-right: 0.375rem;
  }
  .xl\:mb-1\.5 {
    margin-bottom: 0.375rem;
  }
  .xl\:ml-1\.5 {
    margin-left: 0.375rem;
  }
  .xl\:m-2 {
    margin: 0.5rem;
  }
  .xl\:mx-2 {
    margin-left: 0.5rem;
    margin-right: 0.5rem;
  }
  .xl\:my-2 {
    margin-top: 0.5rem;
    margin-bottom: 0.5rem;
  }
  .xl\:ms-2 {
    margin-inline-start: 0.5rem;
  }
  .xl\:me-2 {
    margin-inline-end: 0.5rem;
  }
  .xl\:mt-2 {
    margin-top: 0.5rem;
  }
  .xl\:mr-2 {
    margin-right: 0.5rem;
  }
  .xl\:mb-2 {
    margin-bottom: 0.5rem;
  }
  .xl\:ml-2 {
    margin-left: 0.5rem;
  }
  .xl\:m-2\.5 {
    margin: 0.625rem;
  }
  .xl\:mx-2\.5 {
    margin-left: 0.625rem;
    margin-right: 0.625rem;
  }
  .xl\:my-2\.5 {
    margin-top: 0.625rem;
    margin-bottom: 0.625rem;
  }
  .xl\:ms-2\.5 {
    margin-inline-start: 0.625rem;
  }
  .xl\:me-2\.5 {
    margin-inline-end: 0.625rem;
  }
  .xl\:mt-2\.5 {
    margin-top: 0.625rem;
  }
  .xl\:mr-2\.5 {
    margin-right: 0.625rem;
  }
  .xl\:mb-2\.5 {
    margin-bottom: 0.625rem;
  }
  .xl\:ml-2\.5 {
    margin-left: 0.625rem;
  }
  .xl\:m-3 {
    margin: 0.75rem;
  }
  .xl\:mx-3 {
    margin-left: 0.75rem;
    margin-right: 0.75rem;
  }
  .xl\:my-3 {
    margin-top: 0.75rem;
    margin-bottom: 0.75rem;
  }
  .xl\:ms-3 {
    margin-inline-start: 0.75rem;
  }
  .xl\:me-3 {
    margin-inline-end: 0.75rem;
  }
  .xl\:mt-3 {
    margin-top: 0.75rem;
  }
  .xl\:mr-3 {
    margin-right: 0.75rem;
  }
  .xl\:mb-3 {
    margin-bottom: 0.75rem;
  }
  .xl\:ml-3 {
    margin-left: 0.75rem;
  }
  .xl\:m-3\.5 {
    margin: 0.875rem;
  }
  .xl\:mx-3\.5 {
    margin-left: 0.875rem;
    margin-right: 0.875rem;
  }
  .xl\:my-3\.5 {
    margin-top: 0.875rem;
    margin-bottom: 0.875rem;
  }
  .xl\:ms-3\.5 {
    margin-inline-start: 0.875rem;
  }
  .xl\:me-3\.5 {
    margin-inline-end: 0.875rem;
  }
  .xl\:mt-3\.5 {
    margin-top: 0.875rem;
  }
  .xl\:mr-3\.5 {
    margin-right: 0.875rem;
  }
  .xl\:mb-3\.5 {
    margin-bottom: 0.875rem;
  }
  .xl\:ml-3\.5 {
    margin-left: 0.875rem;
  }
  .xl\:m-4 {
    margin: 1rem;
  }
  .xl\:mx-4 {
    margin-left: 1rem;
    margin-right: 1rem;
  }
  .xl\:my-4 {
    margin-top: 1rem;
    margin-bottom: 1rem;
  }
  .xl\:ms-4 {
    margin-inline-start: 1rem;
  }
  .xl\:me-4 {
    margin-inline-end: 1rem;
  }
  .xl\:mt-4 {
    margin-top: 1rem;
  }
  .xl\:-mt-4 {
    margin-top: -1rem;
  }
  .xl\:mr-4 {
    margin-right: 1rem;
  }
  .xl\:mb-4 {
    margin-bottom: 1rem;
  }
  .xl\:ml-4 {
    margin-left: 1rem;
  }
  .xl\:m-5 {
    margin: 1.25rem;
  }
  .xl\:mx-5 {
    margin-left: 1.25rem;
    margin-right: 1.25rem;
  }
  .xl\:my-5 {
    margin-top: 1.25rem;
    margin-bottom: 1.25rem;
  }
  .xl\:mt-5 {
    margin-top: 1.25rem;
  }
  .xl\:mr-5 {
    margin-right: 1.25rem;
  }
  .xl\:mb-5 {
    margin-bottom: 1.25rem;
  }
  .xl\:ml-5 {
    margin-left: 1.25rem;
  }
  .xl\:m-6 {
    margin: 1.5rem;
  }
  .xl\:mx-6 {
    margin-left: 1.5rem;
    margin-right: 1.5rem;
  }
  .xl\:my-6 {
    margin-top: 1.5rem;
    margin-bottom: 1.5rem;
  }
  .xl\:mt-6 {
    margin-top: 1.5rem;
  }
  .xl\:mr-6 {
    margin-right: 1.5rem;
  }
  .xl\:mb-6 {
    margin-bottom: 1.5rem;
  }
  .xl\:ml-6 {
    margin-left: 1.5rem;
  }
  .xl\:m-7 {
    margin: 1.75rem;
  }
  .xl\:mx-7 {
    margin-left: 1.75rem;
    margin-right: 1.75rem;
  }
  .xl\:my-7 {
    margin-top: 1.75rem;
    margin-bottom: 1.75rem;
  }
  .xl\:mt-7 {
    margin-top: 1.75rem;
  }
  .xl\:mr-7 {
    margin-right: 1.75rem;
  }
  .xl\:mb-7 {
    margin-bottom: 1.75rem;
  }
  .xl\:ml-7 {
    margin-left: 1.75rem;
  }
  .xl\:m-8 {
    margin: 2rem;
  }
  .xl\:m-9 {
    margin: 2.25rem;
  }
  .xl\:mx-8 {
    margin-left: 2rem;
    margin-right: 2rem;
  }
  .xl\:my-8 {
    margin-top: 2rem;
    margin-bottom: 2rem;
  }
  .xl\:mx-9 {
    margin-left: 2.25rem;
    margin-right: 2.25rem;
  }
  .xl\:my-9 {
    margin-top: 2.25rem;
    margin-bottom: 2.25rem;
  }
  .xl\:mt-8 {
    margin-top: 2rem;
  }
  .xl\:mr-8 {
    margin-right: 2rem;
  }
  .xl\:mb-8 {
    margin-bottom: 2rem;
  }
  .xl\:ml-8 {
    margin-left: 2rem;
  }
  .xl\:mt-9 {
    margin-top: 2.25rem;
  }
  .xl\:mb-9 {
    margin-bottom: 2.25rem;
  }
  .xl\:mt-12 {
    margin-top: 3rem;
  }
  .xl\:mb-12 {
    margin-top: 3rem;
  }
  .xl\:mr-12 {
    margin-right: 3rem;
  }
  .xl\:ml-12 {
    margin-left: 3rem;
  }
  .xl\:my-12 {
    margin-top: 3rem;
    margin-bottom: 3rem;
  }
  .xl\:mx-12 {
    margin-left: 3rem;
    margin-right: 3rem;
  }
  .xl\:-mx-4 {
    margin-left: -1rem;
    margin-right: -1rem;
  }
  .xl\:-my-4 {
    margin-top: -1rem;
    margin-bottom: -1rem;
  }
  .xl\:p-0 {
    padding: 0px;
  }
  .xl\:px-0 {
    padding-left: 0px;
    padding-right: 0px;
  }
  .xl\:py-0 {
    padding-top: 0px;
    padding-bottom: 0px;
  }
  .xl\:pt-0 {
    padding-top: 0px;
  }
  .xl\:pr-0 {
    padding-right: 0px;
  }
  .xl\:pb-0 {
    padding-bottom: 0px;
  }
  .xl\:pl-0 {
    padding-left: 0px;
  }
  .xl\:p-px {
    padding: 1px;
  }
  .xl\:px-px {
    padding-left: 1px;
    padding-right: 1px;
  }
  .xl\:py-px {
    padding-top: 1px;
    padding-bottom: 1px;
  }
  .xl\:pt-px {
    padding-top: 1px;
  }
  .xl\:pr-px {
    padding-right: 1px;
  }
  .xl\:pb-px {
    padding-bottom: 1px;
  }
  .xl\:pl-px {
    padding-left: 1px;
  }
  .xl\:p-0\.5 {
    padding: 0.125rem;
  }
  .xl\:px-0\.5 {
    padding-left: 0.125rem;
    padding-right: 0.125rem;
  }
  .xl\:py-0\.5 {
    padding-top: 0.125rem;
    padding-bottom: 0.125rem;
  }
  .xl\:pt-0\.5 {
    padding-top: 0.125rem;
  }
  .xl\:pr-0\.5 {
    padding-right: 0.125rem;
  }
  .xl\:pb-0\.5 {
    padding-bottom: 0.125rem;
  }
  .xl\:pl-0\.5 {
    padding-left: 0.125rem;
  }
  .xl\:p-1 {
    padding: 0.25rem;
  }
  .xl\:px-1 {
    padding-left: 0.25rem;
    padding-right: 0.25rem;
  }
  .xl\:py-1 {
    padding-top: 0.25rem;
    padding-bottom: 0.25rem;
  }
  .xl\:pt-1 {
    padding-top: 0.25rem;
  }
  .xl\:pr-1 {
    padding-right: 0.25rem;
  }
  .xl\:pb-1 {
    padding-bottom: 0.25rem;
  }
  .xl\:pl-1 {
    padding-left: 0.25rem;
  }
  .xl\:p-1\.5 {
    padding: 0.375rem;
  }
  .xl\:px-1\.5 {
    padding-left: 0.375rem;
    padding-right: 0.375rem;
  }
  .xl\:py-1\.5 {
    padding-top: 0.375rem;
    padding-bottom: 0.375rem;
  }
  .xl\:pt-1\.5 {
    padding-top: 0.375rem;
  }
  .xl\:pr-1\.5 {
    padding-right: 0.375rem;
  }
  .xl\:pb-1\.5 {
    padding-bottom: 0.375rem;
  }
  .xl\:pl-1\.5 {
    padding-left: 0.375rem;
  }
  .xl\:p-2 {
    padding: 0.5rem;
  }
  .xl\:px-2 {
    padding-left: 0.5rem;
    padding-right: 0.5rem;
  }
  .xl\:py-2 {
    padding-top: 0.5rem;
    padding-bottom: 0.5rem;
  }
  .xl\:pt-2 {
    padding-top: 0.5rem;
  }
  .xl\:pr-2 {
    padding-right: 0.5rem;
  }
  .xl\:pb-2 {
    padding-bottom: 0.5rem;
  }
  .xl\:pl-2 {
    padding-left: 0.5rem;
  }
  .xl\:p-2\.5 {
    padding: 0.625rem;
  }
  .xl\:px-2\.5 {
    padding-left: 0.625rem;
    padding-right: 0.625rem;
  }
  .xl\:py-2\.5 {
    padding-top: 0.625rem;
    padding-bottom: 0.625rem;
  }
  .xl\:pt-2\.5 {
    padding-top: 0.625rem;
  }
  .xl\:pr-2\.5 {
    padding-right: 0.625rem;
  }
  .xl\:pb-2\.5 {
    padding-bottom: 0.625rem;
  }
  .xl\:pl-2\.5 {
    padding-left: 0.625rem;
  }
  .xl\:p-3 {
    padding: 0.75rem;
  }
  .xl\:px-3 {
    padding-left: 0.75rem;
    padding-right: 0.75rem;
  }
  .xl\:py-3 {
    padding-top: 0.75rem;
    padding-bottom: 0.75rem;
  }
  .xl\:pt-3 {
    padding-top: 0.75rem;
  }
  .xl\:pr-3 {
    padding-right: 0.75rem;
  }
  .xl\:pb-3 {
    padding-bottom: 0.75rem;
  }
  .xl\:pl-3 {
    padding-left: 0.75rem;
  }
  .xl\:p-3\.5 {
    padding: 0.875rem;
  }
  .xl\:px-3\.5 {
    padding-left: 0.875rem;
    padding-right: 0.875rem;
  }
  .xl\:py-3\.5 {
    padding-top: 0.875rem;
    padding-bottom: 0.875rem;
  }
  .xl\:pt-3\.5 {
    padding-top: 0.875rem;
  }
  .xl\:pr-3\.5 {
    padding-right: 0.875rem;
  }
  .xl\:pb-3\.5 {
    padding-bottom: 0.875rem;
  }
  .xl\:pl-3\.5 {
    padding-left: 0.875rem;
  }
  .xl\:p-4 {
    padding: 1rem;
  }
  .xl\:px-4 {
    padding-left: 1rem;
    padding-right: 1rem;
  }
  .xl\:-px-4 {
    padding-left: -1rem;
    padding-right: -1rem;
  }
  .xl\:py-4 {
    padding-top: 1rem;
    padding-bottom: 1rem;
  }
  .xl\:-py-4 {
    padding-top: -1rem;
    padding-bottom: -1rem;
  }
  .xl\:pt-4 {
    padding-top: 1rem;
  }
  .xl\:pr-4 {
    padding-right: 1rem;
  }
  .xl\:pb-4 {
    padding-bottom: 1rem;
  }
  .xl\:pl-4 {
    padding-left: 1rem;
  }
  .xl\:p-5 {
    padding: 1.25rem;
  }
  .xl\:px-5 {
    padding-left: 1.25rem;
    padding-right: 1.25rem;
  }
  .xl\:py-5 {
    padding-top: 1.25rem;
    padding-bottom: 1.25rem;
  }
  .xl\:pt-5 {
    padding-top: 1.25rem;
  }
  .xl\:pr-5 {
    padding-right: 1.25rem;
  }
  .xl\:pb-5 {
    padding-bottom: 1.25rem;
  }
  .xl\:pl-5 {
    padding-left: 1.25rem;
  }
  .xl\:p-6 {
    padding: 1.5rem;
  }
  .xl\:px-6 {
    padding-left: 1.5rem;
    padding-right: 1.5rem;
  }
  .xl\:py-6 {
    padding-top: 1.5rem;
    padding-bottom: 1.5rem;
  }
  .xl\:pt-6 {
    padding-top: 1.5rem;
  }
  .xl\:pr-6 {
    padding-right: 1.5rem;
  }
  .xl\:pb-6 {
    padding-bottom: 1.5rem;
  }
  .xl\:pl-6 {
    padding-left: 1.5rem;
  }
  .xl\:p-7 {
    padding: 1.75rem;
  }
  .xl\:px-7 {
    padding-left: 1.75rem;
    padding-right: 1.75rem;
  }
  .xl\:py-7 {
    padding-top: 1.75rem;
    padding-bottom: 1.75rem;
  }
  .xl\:pt-7 {
    padding-top: 1.75rem;
  }
  .xl\:pr-7 {
    padding-right: 1.75rem;
  }
  .xl\:pb-7 {
    padding-bottom: 1.75rem;
  }
  .xl\:pl-7 {
    padding-left: 1.75rem;
  }
  .xl\:p-8 {
    padding: 2rem;
  }
  .xl\:px-8 {
    padding-left: 2rem;
    padding-right: 2rem;
  }
  .xl\:py-8 {
    padding-top: 2rem;
    padding-bottom: 2rem;
  }
  .xl\:pt-8 {
    padding-top: 2rem;
  }
  .xl\:pr-8 {
    padding-right: 2rem;
  }
  .xl\:pb-8 {
    padding-bottom: 2rem;
  }
  .xl\:pl-8 {
    padding-left: 2rem;
  }
  .xl\:p-9 {
    padding: 2.25rem;
  }
  .xl\:px-9 {
    padding-left: 2.25rem;
    padding-right: 2.25rem;
  }
  .xl\:py-9 {
    padding-top: 2.25rem;
    padding-bottom: 2.25rem;
  }
  .xl\:pt-9 {
    padding-top: 2.25rem;
  }
  .xl\:pr-9 {
    padding-right: 2.25rem;
  }
  .xl\:pb-9 {
    padding-bottom: 2.25rem;
  }
  .xl\:pl-9 {
    padding-left: 2.25rem;
  }
  .xl\:p-10 {
    padding: 2.5rem;
  }
  .xl\:px-10 {
    padding-left: 2.5rem;
    padding-right: 2.5rem;
  }
  .xl\:py-10 {
    padding-top: 2.5rem;
    padding-bottom: 2.5rem;
  }
  .xl\:pt-10 {
    padding-top: 2.5rem;
  }
  .xl\:pr-10 {
    padding-right: 2.5rem;
  }
  .xl\:pb-10 {
    padding-bottom: 2.5rem;
  }
  .xl\:pl-10 {
    padding-left: 2.5rem;
  }
  .xl\:p-11 {
    padding: 2.75rem;
  }
  .xl\:px-11 {
    padding-left: 2.75rem;
    padding-right: 2.75rem;
  }
  .xl\:py-11 {
    padding-top: 2.75rem;
    padding-bottom: 2.75rem;
  }
  .xl\:pt-11 {
    padding-top: 2.75rem;
  }
  .xl\:pr-11 {
    padding-right: 2.75rem;
  }
  .xl\:pb-11 {
    padding-bottom: 2.75rem;
  }
  .xl\:pl-11 {
    padding-left: 2.75rem;
  }
  .xl\:p-12 {
    padding: 3rem;
  }
  .xl\:px-12 {
    padding-left: 3rem;
    padding-right: 3rem;
  }
  .xl\:py-12 {
    padding-top: 3rem;
    padding-bottom: 3rem;
  }
  .xl\:pt-12 {
    padding-top: 3rem;
  }
  .xl\:pr-12 {
    padding-right: 3rem;
  }
  .xl\:pb-12 {
    padding-bottom: 3rem;
  }
  .xl\:pl-12 {
    padding-left: 3rem;
  }
  .xl\:p-14 {
    padding: 3.5rem;
  }
  .xl\:px-14 {
    padding-left: 3.5rem;
    padding-right: 3.5rem;
  }
  .xl\:py-14 {
    padding-top: 3.5rem;
    padding-bottom: 3.5rem;
  }
  .xl\:pt-14 {
    padding-top: 3.5rem;
  }
  .xl\:pr-14 {
    padding-right: 3.5rem;
  }
  .xl\:pb-14 {
    padding-bottom: 3.5rem;
  }
  .xl\:pl-14 {
    padding-left: 3.5rem;
  }
  .xl\:p-16 {
    padding: 4rem;
  }
  .xl\:px-16 {
    padding-left: 4rem;
    padding-right: 4rem;
  }
  .xl\:py-16 {
    padding-top: 4rem;
    padding-bottom: 4rem;
  }
  .xl\:pt-16 {
    padding-top: 4rem;
  }
  .xl\:pr-16 {
    padding-right: 4rem;
  }
  .xl\:pb-16 {
    padding-bottom: 4rem;
  }
  .xl\:pl-16 {
    padding-left: 4rem;
  }
}